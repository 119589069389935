import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp9(x, z) {
  return safeDivByZero(z * x, 1000);
}

export function quadrant2yTyp9(x, z) {
  return safeDivByZero(z, x);
}

export function quadrant3yTyp9(x, z) {
  return x - z;
}

export function quadrant4yTyp9(x, z) {
  return x + z;
}
