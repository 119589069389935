import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import MuiButton from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const Button = withStyles({
  root: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
})(MuiButton);

const DisabledButtonWithTooltip = ({
  tooltipText,
  disabled,
  onClick,
  ...other
}) => {
  const adjustedButtonProps = {
    disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };

  return (
    <>
      {disabled ? (
        <Tooltip title={tooltipText}>
          <Button {...other} {...adjustedButtonProps} />
        </Tooltip>
      ) : (
        <Button {...other} {...adjustedButtonProps} />
      )}
    </>
  );
};

export default DisabledButtonWithTooltip;
