import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import IconInformationButton from "./IconButtonInformation";
import { useTranslation } from "react-i18next";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DiagramTypeInfoDialog = ({ diagram }) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconInformationButton open={handleClickOpen} />
      <Dialog
        open={open}
        maxWidth="xs"
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("diagramInfo")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h4> {diagram.title}</h4>
            <p style={{ textAlign: "justify" }}>{diagram.description}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Schliessen")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DiagramTypeInfoDialog;
