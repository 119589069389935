import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp4(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp4(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant3zTyp4(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp4(x, y) {
  return safeDivByZero(x, y);
}
