import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp3(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant2zTyp3(x, y) {
  return x - y;
}

export function quadrant3zTyp3(x, y) {
  return x + y;
}

export function quadrant4zTyp3(x, y) {
  return safeDivByZero(y, x) * 1000;
}
