import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp15(y, z) {
  return safeDivByZero(100 * y, z);
}

export function quadrant2xTyp15(y, z) {
  return (y * z) / 1000;
}

export function quadrant3xTyp15(y, z) {
  return y * z;
}

export function quadrant4xTyp15(y, z) {
  return y * z;
}
