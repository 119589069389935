import React from "react";
import Button from "@material-ui/core/Button";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import PropTypes from "prop-types";

export default function IconButtonInformation({ open }) {
  return (
    <div>
      <Button
        variant="outlined"
        startIcon={<HelpOutlineOutlinedIcon />}
        onClick={open}
        style={{
          display: "inline-block",
          marginTop: 9,
          borderStyle: "none",
        }}
      />
    </div>
  );
}

IconButtonInformation.propTypes = {
  open: PropTypes.func.isRequired,
};
