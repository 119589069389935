import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp6(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant2zTyp6(x, y) {
  return (1 - safeDivByZero(y, x)) * 100;
}

export function quadrant3zTyp6(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp6(x, y) {
  return safeDivByZero(y, x);
}
