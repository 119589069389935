import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp2(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp2(x, y) {
  return y - x;
}

export function quadrant3zTyp2(x, y) {
  return x + y;
}

export function quadrant4zTyp2(x, y) {
  return safeDivByZero(x, y);
}
