import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp18(x, y) {
  return x * y;
}

export function quadrant2zTyp18(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant3zTyp18(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant4zTyp18(x, y) {
  return safeDivByZero(y, x);
}
