import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { IconButton } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ close }) => {
  const { t } = useTranslation();

  const { push } = useHistory();

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      open
    >
      <DialogTitle id="alert-dialog-slide-title">
        <IconButton
          style={{ position: "absolute", left: "5%", top: "3%" }}
          aria-label="back"
          onClick={() => push("/welcome")}
        >
          <ArrowBackIcon />
        </IconButton>
        {t("questionDialog.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div>{t("questionDialog.text")}</div>
          <br />
          <div> {t("questionDialog.question")}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close("yes")} color="primary">
          {t("questionDialog.buttonYes")}
        </Button>
        <Button onClick={() => close("no")} color="primary">
          {t("questionDialog.buttonNo")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
