import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LayoutTextFields from "./LayoutTextFields";
import { useData } from "../../DataProvider";
import { isObjectEmpty, isPositiveNumber } from "../../../helpers/util";
import AxisLabel from "./AxisLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  helperTextHeader: {
    color: theme.palette.primary.main,
    font: "normal normal bold 12px/14px Helvetica",
  },
  helperTextBody: {
    color: theme.palette.primary.main,
    font: "normal normal normal 12px/14px Helvetica",
    textAlign: "justify",
  },
  btn: {
    backgroundColor: "transparent",
    border: "none",
    marginLeft: 20,
  },
}));

function ConfigTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
ConfigTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ConfigTabs({ qNumber, onChanged }) {
  const initState = {
    quadrants: [{}, {}, {}, {}],
  };

  const { userProjects, projectId } = useData();
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(qNumber - 1);
  const [quadrantXAxis, setQuadrantXAxis] = React.useState(initState);
  const [quadrantYAxis, setQuadrantYAxis] = React.useState(initState);
  const [quadrantDataSeries, setQuadrantDataSeries] = React.useState(initState);
  const [decimalPlaces, setDecimalPlaces] = React.useState([2, 2, 2, 2]);
  const { t } = useTranslation();

  ConfigTabs.propTypes = {
    qNumber: PropTypes.number.isRequired,
    onChanged: PropTypes.func.isRequired,
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    onChanged(quadrantXAxis, quadrantYAxis, quadrantDataSeries, decimalPlaces);
  });

  function calculateNeighborIndexForXAxis(index) {
    let neighborIndex = index;
    if (index === 0) neighborIndex = 3;
    else if (index === 3) neighborIndex = 0;
    else if (index === 1) neighborIndex = 2;
    else if (index === 2) neighborIndex = 1;
    return neighborIndex;
  }

  function calculateNeighborIndexForYAxis(index) {
    let neighborIndex = index;
    if (index === 0) neighborIndex = 1;
    else if (index === 1) neighborIndex = 0;
    else if (index === 2) neighborIndex = 3;
    else if (index === 3) neighborIndex = 2;
    return neighborIndex;
  }

  function handleInputX(val, key, dataOfQuadrant, index) {
    const inputValue = val.replace(",", ".");
    if (!isPositiveNumber(inputValue)) return;
    const tmpDataObj = dataOfQuadrant;
    tmpDataObj[key] = inputValue;
    const neighborIndex = calculateNeighborIndexForXAxis(index);
    const { areXAxisCongruent } = userProjects[projectId];
    const newQuadrants = [...quadrantXAxis.quadrants];

    newQuadrants[index] = { ...tmpDataObj };
    if (areXAxisCongruent) {
      newQuadrants[neighborIndex] = { ...tmpDataObj };
    }

    setQuadrantXAxis({ quadrants: newQuadrants });
  }

  function handleInputY(val, key, dataOfQuadrant, index) {
    const inputValue = val.replace(",", ".");
    if (!isPositiveNumber(inputValue)) return;

    const tmpDataObj = dataOfQuadrant;
    tmpDataObj[key] = inputValue;
    const neighborIndex = calculateNeighborIndexForYAxis(index);
    const newQuadrants = [...quadrantYAxis.quadrants];

    newQuadrants[index] = { ...tmpDataObj };
    newQuadrants[neighborIndex] = { ...tmpDataObj };

    setQuadrantYAxis({ quadrants: newQuadrants });
  }

  function handleInputDataSeries(val, key, dataOfQuadrant, index) {
    const inputValue = val.replace(",", ".");
    if (!isPositiveNumber(inputValue)) return;

    const tmpDataObj = dataOfQuadrant;
    tmpDataObj[key] = inputValue;
    if (key === "number" && inputValue > 20) tmpDataObj[key] = 20;

    const newQuadrants = [...quadrantDataSeries.quadrants];
    newQuadrants[index] = { ...tmpDataObj };

    setQuadrantDataSeries({ quadrants: newQuadrants });
  }

  function changeHelperDesc(headerText, bodyText) {
    const htag = document.getElementById("helpTextHeader");
    htag.innerHTML = headerText;
    const ptag = document.getElementById("helpTextParagraph");
    ptag.innerHTML = bodyText;
  }

  function chooseData(valueFromDataJson, index, state) {
    const quadrantObject = state.quadrants[index];
    if (!isObjectEmpty(quadrantObject)) {
      return quadrantObject;
    }
    return { ...valueFromDataJson };
  }

  useEffect(() => {
    const newStateArray = [];
    userProjects[projectId].quadrants.forEach((quadrant) => {
      newStateArray.push(quadrant.fractionalDigits);
    });
    setDecimalPlaces(newStateArray);
  }, [projectId, userProjects]);

  const listOfTabPanels = userProjects[projectId].quadrants.map(
    (quadrant, index) => (
      <ConfigTabPanel value={tabIndex} index={index} key={quadrant.number}>
        <Grid container align="center">
          <Grid item xs={3}>
            <LayoutTextFields
              legendName={t("Ueberschrift_Legende_1")}
              changeText={changeHelperDesc}
              data={chooseData(quadrant.x, index, quadrantXAxis)}
              handleInput={handleInputX}
              index={index}
              key={`xAxis${quadrant.number}`}
            />
          </Grid>
          <Grid item xs={3}>
            <LayoutTextFields
              legendName={t("Ueberschrift_Legende_2")}
              changeText={changeHelperDesc}
              data={chooseData(quadrant.y, index, quadrantYAxis)}
              handleInput={handleInputY}
              index={index}
              key={`yAxis${quadrant.number}`}
            />
          </Grid>
          <Grid item xs={3}>
            <LayoutTextFields
              legendName={t("Ueberschrift_Legende_3")}
              changeText={changeHelperDesc}
              data={chooseData(quadrant.dataseries, index, quadrantDataSeries)}
              handleInput={handleInputDataSeries}
              index={index}
              key={`dataseries${quadrant.number}`}
            />
            <AxisLabel
              axis="y"
              tabIndex={tabIndex}
              label={t("Label_Y_Axis")}
              qNumber={qNumber}
              userProject={userProjects[projectId]}
            />

            <AxisLabel
              axis="x"
              tabIndex={tabIndex}
              position="top"
              label={t("Label_X_Axis_top")}
              qNumber={qNumber}
              userProject={userProjects[projectId]}
            />

            <AxisLabel
              axis="x"
              tabIndex={tabIndex}
              position="bottom"
              label={t("Label_X_Axis_bottom")}
              qNumber={qNumber}
              userProject={userProjects[projectId]}
            />
          </Grid>
          <Grid item xs={3}>
            <div>
              <h4 id="helpTextHeader" className={classes.helperTextHeader}>
                {t("Ueberschrift_Einstellungen")}
              </h4>
              <div id="helpTextParagraph" className={classes.helperTextBody}>
                {t("Text_Einstellungen")}
              </div>
            </div>
          </Grid>
        </Grid>
      </ConfigTabPanel>
    )
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label={t("Ueberschrift_Diagramm_anpassen")}
        >
          <Tab label="Quadrant 1" {...tabProps(0)} />
          <Tab label="Quadrant 2" {...tabProps(1)} />
          <Tab label="Quadrant 3" {...tabProps(2)} />
          <Tab label="Quadrant 4" {...tabProps(3)} />
        </Tabs>
      </AppBar>
      <div>{listOfTabPanels}</div>
    </div>
  );
}
