import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp14(y, z) {
  return safeDivByZero(1000 * y, z);
}

export function quadrant2xTyp14(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant3xTyp14(y, z) {
  return y * z;
}

export function quadrant4xTyp14(y, z) {
  return y * z;
}
