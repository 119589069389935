import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp15(x, z) {
  return (x * z) / 100;
}

export function quadrant2yTyp15(x, z) {
  return safeDivByZero(1000 * x, z);
}

export function quadrant3yTyp15(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp15(x, z) {
  return safeDivByZero(x, z);
}
