import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp1(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant2xTyp1(y, z) {
  return y * z;
}

export function quadrant3xTyp1(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant4xTyp1(y, z) {
  return safeDivByZero(z, y);
}
