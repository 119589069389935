import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp12(x, z) {
  return (x * z) / 100;
}

export function quadrant2yTyp12(x, z) {
  return safeDivByZero(100 * x, z);
}

export function quadrant3yTyp12(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp12(x, z) {
  return safeDivByZero(x, z);
}
