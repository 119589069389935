import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp18(x, z) {
  return safeDivByZero(z, x);
}

export function quadrant2yTyp18(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant3yTyp18(x, z) {
  return x * z;
}

export function quadrant4yTyp18(x, z) {
  return x * z;
}
