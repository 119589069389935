import { TextField } from "@material-ui/core";
import React, { useRef, useState } from "react";
import Draggable from "react-draggable";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { PropTypes } from "prop-types";
import { useData } from "../DataProvider";

export default function DraggableTextField({
  deleteValue,
  id,
  posX,
  posY,
  value,
  width,
  userProjects,
  updateUserProjects,
}) {
  const [inputValue, setInputValue] = useState(value);
  const [deltaPosition, setDeltaPosition] = useState({ x: posX, y: posY });
  const [draggableId, setDraggableId] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const { projectId } = useData();

  // eslint-disable-next-line
  let deltaX = 0;
  // eslint-disable-next-line
  let deltaY = 0;

  const inputRef = useRef();
  const buttonRef = useRef();

  const save = () => {
    const { textFields } = userProjects[projectId];
    const index = textFields.findIndex((el) => el.id === id);
    if (index === -1) {
      textFields.push({
        id,
        posX: deltaPosition.x,
        posY: deltaPosition.y,
        inputValue,
      });
    } else {
      textFields[index] = {
        id,
        posX: deltaPosition.x,
        posY: deltaPosition.y,
        inputValue,
      };
    }

    const newUserProject = { ...userProjects };

    const userProjectsLocalStorage = JSON.parse(
      localStorage.getItem("userProjects")
    );

    if (userProjectsLocalStorage) {
      const allQuadrants = userProjectsLocalStorage[projectId].quadrants;

      allQuadrants.forEach((quadrant, index_) => {
        newUserProject[projectId].quadrants[index_].workingLines =
          quadrant.workingLines;
      });
    }

    newUserProject[projectId].textFields = textFields;
    updateUserProjects(newUserProject);
    setDraggableId(Math.floor(Math.random() * 1000));
  };

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: Math.round(Number(x + ui.deltaX)),
      y: Math.round(Number(y + ui.deltaY)),
    });

    deltaX = Math.round(Number(x + ui.deltaX));
    deltaY = Math.round(Number(y + ui.deltaY));
  };

  const handleIsFocused = () => {
    setIsFocused(true);
  };

  const handleIsNotFocused = (event) => {
    save();
    setIsFocused(false);
    // this is a workaround because button click handler is not called when focus is left
    if (event.relatedTarget === buttonRef.current) {
      event.relatedTarget.click();
    }
  };

  return (
    <div style={{ position: "absolute", zIndex: 1000, left: posX, top: posY }}>
      <Draggable
        key={`draggable_${draggableId}`}
        ref={inputRef}
        style={{
          width,
        }}
        onDrag={handleDrag}
      >
        <div style={{ display: "flex", alignItems: "center", width }}>
          <TextField
            id="standard-multiline-flexible"
            multiline
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            style={{
              width,
              zIndex: 10000,
              fontFamily: "Arial, Helvetica, sans-serif",
              border: "1px dotted blue",
              backgroundColor: isFocused ? "#d9edff" : "white",
            }}
            onFocus={handleIsFocused}
            onBlur={handleIsNotFocused}
          />

          <div className="font-icon-wrapper">
            <IconButton
              aria-label="delete"
              style={{ visibility: isFocused ? "inherit" : "hidden" }}
              ref={buttonRef}
              disabled={!isFocused}
              onClick={() => {
                deleteValue(id);
                setIsFocused(false);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </Draggable>
    </div>
  );
}

DraggableTextField.propTypes = {
  deleteValue: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  posX: PropTypes.number.isRequired,
  posY: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
