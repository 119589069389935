import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp19(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant2xTyp19(y, z) {
  return y * z;
}

export function quadrant3xTyp19(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant4xTyp19(y, z) {
  return y * z;
}
