import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp11(x, y) {
  return safeDivByZero(y, x) * 100;
}

export function quadrant2zTyp11(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant3zTyp11(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp11(x, y) {
  return safeDivByZero(x, y);
}
