import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp7(y, z) {
  return safeDivByZero(1000 * y, z);
}

export function quadrant2xTyp7(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant3xTyp7(y, z) {
  return y * z;
}

export function quadrant4xTyp7(y, z) {
  return y * z;
}
