import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PngLogo from "../../../icons/QuadrantenMenue.png";
import SvgLogo from "../../../icons/QuadrantenMenue.svg";
import { useData } from "../../DataProvider";

const useStyles = makeStyles({
  box: {
    display: "flex",
    alignItems: "center",
  },
  setPositionToRight: {
    justifyContent: "flex-end",
  },
  button: {
    width: "30px",
    padding: 0,
    margin: 0,
    background: "none",
    color: "inherit",
    border: "none",
    cursor: "pointer",
  },
});

export default function ContextMenuLabelLeftSide({
  qNumber,
  name,
  handleClick,
}) {
  const classes = useStyles();
  const { isOnSaving } = useData();
  return (
    <div className={`${classes.box} ${classes.setPositionToRight}`}>
      <span id={`ContextMenuLabelLeftSide_q${qNumber}`}>{name}</span>
      <button
        id={`ContextMenuButtonLeftSide_q${qNumber}`}
        type="button"
        className={classes.button}
        onClick={handleClick}
      >
        <img
          id={`image_logo_${qNumber}`}
          src={isOnSaving ? PngLogo : SvgLogo}
          style={{ maxWidth: "25px" }}
          width="30px"
          alt="diagramIcon"
        />
      </button>
    </div>
  );
}
