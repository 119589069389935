import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp10(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant2yTyp10(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant3yTyp10(x, z) {
  return safeDivByZero(z, x);
}

export function quadrant4yTyp10(x, z) {
  return safeDivByZero(x, z);
}
