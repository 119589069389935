export function quadrant1yTyp3(x, z) {
  return x * z;
}

export function quadrant2yTyp3(x, z) {
  return x - z;
}

export function quadrant3yTyp3(x, z) {
  return z - x;
}

export function quadrant4yTyp3(x, z) {
  return (x * z) / 1000;
}
