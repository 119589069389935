import React, { useEffect, useState, useCallback } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemIcon, MenuList, Tooltip } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import ChangeDirIcon1 from "../../../icons/Arbeitslinie_ChangeDirection.svg";
import ChangeDirIcon2 from "../../../icons/Arbeitslinie_ChangeDirection_1.svg";
import ChangeDirIcon3 from "../../../icons/Arbeitslinie_ChangeDirection_2.svg";
import ChangeDirIcon4 from "../../../icons/Arbeitslinie_ChangeDirection_3.svg";
import ExitIcon from "../../../icons/Icons_gesamt_Arbeitslinie_ChangeDirection_Exit.svg";

export default function ArrowMenu({ selectedItem, onSelected, imageRotation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuHeaderIcon, setMenuHeaderIcon] = useState(ExitIcon);
  const [selectedMenuItem, setSelectedMenuItem] = useState(selectedItem);
  const { t } = useTranslation();

  const imageStyle = {
    width: "100%",
    height: 44,
    maxHeight: 100,
    maxWidth: 40,
    transform: `rotate(${imageRotation}deg)`,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchIcon = useCallback(
    (value) => {
      if (value !== selectedMenuItem) setSelectedMenuItem(value);
      switch (value) {
        case 1:
          setMenuHeaderIcon(ChangeDirIcon1);
          break;
        case 2:
          setMenuHeaderIcon(ChangeDirIcon2);
          break;
        case 3:
          setMenuHeaderIcon(ChangeDirIcon3);
          break;
        case 4:
          setMenuHeaderIcon(ChangeDirIcon4);
          break;
        case 5:
          setMenuHeaderIcon(ExitIcon);
          break;
        default:
          break;
      }
    },
    [selectedMenuItem]
  );

  const onMenuItemClicked = (value) => {
    switchIcon(value);
    onSelected(value);
    handleClose();
  };

  useEffect(() => {
    switchIcon(selectedItem);
  }, [selectedItem, switchIcon]);

  return (
    <div>
      <Tooltip title={t("ToolTip_Pfeilrichtung")} placement="top">
        <img
          src={menuHeaderIcon}
          alt="diagramIcon"
          style={imageStyle}
          onClick={handleClick}
        />
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuList>
          <MenuItem
            onClick={() => onMenuItemClicked(1)}
            selected={selectedMenuItem === 1}
          >
            <ListItemIcon>
              <img src={ChangeDirIcon1} alt="diagramIcon" style={imageStyle} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            onClick={() => onMenuItemClicked(2)}
            selected={selectedMenuItem === 2}
          >
            <ListItemIcon>
              <img src={ChangeDirIcon2} alt="diagramIcon" style={imageStyle} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            onClick={() => onMenuItemClicked(3)}
            selected={selectedMenuItem === 3}
          >
            <ListItemIcon>
              <img src={ChangeDirIcon3} alt="diagramIcon" style={imageStyle} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            onClick={() => onMenuItemClicked(4)}
            selected={selectedMenuItem === 4}
          >
            <ListItemIcon>
              <img src={ChangeDirIcon4} alt="diagramIcon" style={imageStyle} />
            </ListItemIcon>
          </MenuItem>
          <MenuItem
            onClick={() => onMenuItemClicked(5)}
            selected={selectedMenuItem === 5}
          >
            <ListItemIcon>
              <img src={ExitIcon} alt="diagramIcon" style={imageStyle} />
            </ListItemIcon>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

ArrowMenu.propTypes = {
  selectedItem: PropTypes.number.isRequired,
  onSelected: PropTypes.func.isRequired,
  imageRotation: PropTypes.number.isRequired,
};
