import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp2(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant2yTyp2(x, z) {
  return x + z;
}

export function quadrant3yTyp2(x, z) {
  return z - x;
}

export function quadrant4yTyp2(x, z) {
  return safeDivByZero(x, z);
}
