import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import PlaneItems from "./PlaneItems";
import { useData } from "../../DataProvider";
import EditableTextField from "../../ui-elements/EditableTextField";
import { getClosedWorkingLines } from "../../../helpers/util";
import Quadrant from "../chartFunctions/Quadrant";

const onSelectedStyle = {
  backgroundColor: "#fff29a",
  width: "100%",
};

const onStandardStyle = {
  backgroundColor: "white",
  width: "100%",
};

export default function PlaneAccordion({ id, title, updateTitle }) {
  const { selectedPlane, setSelectedPlane, setSelectedPlaneItem, planeItems } =
    useData();

  const [showAllElements, setShowAllElements] = useState();
  const [newPlaneItems, setPlaneItems] = useState(planeItems);

  const handleChange = () => setShowAllElements(!showAllElements);

  const updateSelectedItems = (accordionId) => {
    setSelectedPlaneItem(undefined);
    setSelectedPlane(accordionId);
  };

  useEffect(() => {
    const allChildrenFalse = planeItems[id - 1]?.every(
      (el) => el.isVisible === false
    );
    const allChildrenTrue = planeItems[id - 1]?.every(
      (el) => el.isVisible === true
    );

    if (allChildrenFalse) setShowAllElements(false);
    else if (allChildrenTrue) setShowAllElements(true);
  }, [id, planeItems]);

  useEffect(() => {
    function removePlaneItemIfNotExistAnymore(closedLines) {
      const instanceIds = closedLines.map((line) => line.instanceId);
      const filteredItems = planeItems.map((item) =>
        item.filter((line) => instanceIds.includes(line.id))
      );
      setPlaneItems(filteredItems);
    }

    const closedLines = getClosedWorkingLines(
      Quadrant.firstQuadrant.workingLines
    );
    removePlaneItemIfNotExistAnymore(closedLines);
  }, [planeItems]);

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        color="primary"
        aria-label="show visibility"
        component="span"
        onClick={() => handleChange(`id_${id}`)}
      >
        {showAllElements ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
      <Accordion
        style={selectedPlane === id ? onSelectedStyle : onStandardStyle}
        defaultExpanded={id === 1}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
          onClick={() => {
            updateSelectedItems(id);
          }}
        >
          <EditableTextField
            value={title}
            noMargin
            onChange={(input) => updateTitle(id, input)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <PlaneItems
            id={id}
            showAllElements={showAllElements}
            planeItems={newPlaneItems}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

PlaneAccordion.propTypes = {
  id: PropTypes.number.isRequired,
};
