/*
 * Calculates the working line for each quadrant. Beginning by a specified quadrant the algorithm goes clockwise
 * around and calculates the combination of the n working lines. The result must be always a closed rectangle.
 */
import { useEffect, useCallback } from "react";
import { getWorkingLineCalculationSubject } from "../components/subjects/d3Subjects";
import { sendMessage } from "../subjects/general";
import Quadrant from "../components/graphs/chartFunctions/Quadrant";
import WorkingLine from "../components/graphs/chartFunctions/WorkingLine";
import { getSelectedWorkingLines } from "../components/subjects/calculationSubjects";
import { extractDrawingInfos, storeWorkingLinesInLocalStorage } from "./util";

export default function WorkingLinesCalculation(
  userProjects,
  setUserProjects,
  projectId
) {
  let subject = null;
  let workingLinesQ1;
  let workingLinesQ2;
  let workingLinesQ3;
  let workingLinesQ4;
  let selectedWorkingLines;

  const getWeightFactor = (index) => {
    const planeItems = JSON.parse(localStorage.getItem("planeItems"));

    if (!planeItems) return 1;

    const weightFactors = planeItems
      .flat()
      .sort((a, b) => a.id - b.id)
      .map((el) => el.weightFactor);

    return +weightFactors[index];
  };

  const sumUpXValuesWithFactor = (workingLines) => {
    let sum = 0;
    workingLines.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1) {
        const factor = getWeightFactor(index);
        sum += workingLine.valueXAxis * factor;
      }
    });
    return sum;
  };

  const sumUpZValuesWithFactor = (workingLines) => {
    let sum = 0;
    workingLines.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1) {
        const factor = getWeightFactor(index);
        sum += workingLine.valueZ * factor;
      }
    });
    return sum;
  };

  const sumUpYValues = (workingLines) => {
    let sum = 0;
    workingLines.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1) {
        sum += workingLine.valueYAxis;
      }
    });
    return sum;
  };

  const sumUpZValues = (workingLines) => {
    let sum = 0;
    workingLines.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1)
        sum += workingLine.valueZ;
    });
    return sum;
  };

  const sumUpXValues = (workingLines) => {
    let sum = 0;
    workingLines.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1)
        sum += workingLine.valueXAxis;
    });
    return sum;
  };

  const calcQ1Variant2Typ1 = (weightedXQ4, weightedYQ2) => {
    const weightedXQ1 = weightedXQ4;
    const weightedYQ1 = weightedYQ2;
    const weightedZQ1 = Quadrant.firstQuadrant.formulaZ(
      weightedXQ1,
      weightedYQ1
    );
    return { weightedXQ1, weightedYQ1, weightedZQ1 };
  };

  const calcQ2Variant2Typ1 = (weightedXQ3, weightedZQ3) => {
    const weightedXQ2 = weightedXQ3;
    let sumZ = 0;
    for (let i = 0; i < workingLinesQ2.length; i++) {
      if (selectedWorkingLines.findIndex((el) => el === i) !== -1) {
        sumZ += workingLinesQ2[i].valueZ * workingLinesQ3[i].valueZ;
      }
    }
    const weightedZQ2 = sumZ / weightedZQ3;
    const weightedYQ2 = Quadrant.secondQuadrant.formulaY(
      weightedXQ2,
      weightedZQ2
    );
    return { weightedXQ2, weightedYQ2, weightedZQ2 };
  };

  const calcQ3Variant2Typ1 = () => {
    const weightedYQ3 = sumUpYValues(workingLinesQ3);
    const weightedZQ3 = sumUpZValues(workingLinesQ3);

    const weightedXQ3 = Quadrant.thirdQuadrant.formulaX(
      weightedYQ3,
      weightedZQ3
    );
    return { weightedXQ3, weightedYQ3, weightedZQ3 };
  };

  const calcQ4Variant2Typ1 = () => {
    const weightedYQ4 = sumUpYValues(workingLinesQ4);
    const weightedZQ4 = sumUpZValues(workingLinesQ4);

    const weightedXQ4 = Quadrant.fourthQuadrant.formulaX(
      weightedYQ4,
      weightedZQ4
    );
    return { weightedXQ4, weightedYQ4, weightedZQ4 };
  };

  const calcQ1Variant1Typ1 = (weightedXQ4, weightedZQ4) => {
    const weightedXQ1 = weightedXQ4;
    let sumZ = 0;
    for (let i = 0; i < workingLinesQ1.length; i++) {
      if (selectedWorkingLines.findIndex((el) => el === i) !== -1) {
        sumZ += workingLinesQ1[i].valueZ * workingLinesQ4[i].valueZ;
      }
    }
    const weightedZQ1 = sumZ / weightedZQ4;
    const weightedYQ1 = Quadrant.firstQuadrant.formulaY(
      weightedXQ1,
      weightedZQ1
    );
    return { weightedXQ1, weightedYQ1, weightedZQ1 };
  };

  const calcQ2Variant1Typ1 = (weightedXQ3, weightedYQ1) => {
    const weightedXQ2 = weightedXQ3;
    const weightedYQ2 = weightedYQ1;
    const weightedZQ2 = Quadrant.secondQuadrant.formulaZ(
      weightedXQ2,
      weightedYQ2
    );
    return { weightedXQ2, weightedYQ2, weightedZQ2 };
  };

  const calcQ4Variant1Typ1 = () => calcQ4Variant2Typ1();
  const calcQ3Variant1Typ1 = () => calcQ3Variant2Typ1();

  const calcQ1Typ4 = () => {
    const weightedXQ1 = sumUpXValues(workingLinesQ1);
    const weightedZQ1 = sumUpZValues(workingLinesQ1);

    const weightedYQ1 = Quadrant.firstQuadrant.formulaY(
      weightedXQ1,
      weightedZQ1
    );
    return { weightedXQ1, weightedYQ1, weightedZQ1 };
  };

  const calcQ2Typ4 = (weightedXQ1, weightedYQ1) => {
    const weightedYQ2 = weightedYQ1;
    let sumZ = 0;
    for (let i = 0; i < workingLinesQ2.length; i++) {
      if (selectedWorkingLines.findIndex((el) => el === i) !== -1) {
        sumZ += workingLinesQ2[i].valueZ * workingLinesQ1[i].valueXAxis;
      }
    }

    const weightedZQ2 = sumZ / weightedXQ1;
    const weightedXQ2 = Quadrant.secondQuadrant.formulaX(
      weightedYQ2,
      weightedZQ2
    );
    return { weightedXQ2, weightedYQ2, weightedZQ2 };
  };

  const calcQ3Typ4 = (weightedXQ2, weightedXQ1) => {
    const weightedXQ3 = weightedXQ2;
    let sumZ = 0;
    for (let i = 0; i < workingLinesQ3.length; i++) {
      if (selectedWorkingLines.findIndex((el) => el === i) !== -1) {
        sumZ += workingLinesQ3[i].valueZ * workingLinesQ1[i].valueXAxis;
      }
    }

    const weightedZQ3 = sumZ / weightedXQ1;
    const weightedYQ3 = Quadrant.thirdQuadrant.formulaY(
      weightedXQ3,
      weightedZQ3
    );
    return { weightedXQ3, weightedYQ3, weightedZQ3 };
  };

  const calcQ4Typ4 = (weightedYQ1, weightedYQ3) => {
    const weightedXQ4 = weightedYQ1;
    const weightedYQ4 = weightedYQ3;
    const weightedZQ4 = Quadrant.fourthQuadrant.formulaZ(
      weightedXQ4,
      weightedYQ4
    );
    return { weightedXQ4, weightedYQ4, weightedZQ4 };
  };

  const calcQ1Typ3 = () => {
    const weightedXQ1 = sumUpXValuesWithFactor(workingLinesQ1);
    const weightedZQ1 = sumUpZValuesWithFactor(workingLinesQ1);

    const weightedYQ1 = Quadrant.firstQuadrant.formulaY(
      weightedXQ1,
      weightedZQ1
    );
    return { weightedXQ1, weightedYQ1, weightedZQ1 };
  };

  const calcQ2Typ3 = (weightedXQ1, weightedYQ1) => {
    const weightedYQ2 = weightedYQ1;
    let sumZ = 0;
    workingLinesQ2.forEach((workingLine, index) => {
      if (selectedWorkingLines.findIndex((el) => el === index) !== -1) {
        const factor = getWeightFactor(index);
        sumZ += workingLine.valueZ * factor;
      }
    });

    const weightedZQ2 = sumZ;
    const weightedXQ2 = Quadrant.secondQuadrant.formulaX(
      weightedYQ2,
      weightedZQ2
    );
    return { weightedXQ2, weightedYQ2, weightedZQ2 };
  };

  const calcQ3Typ3 = (weightedXQ2, weightedYQ4) => {
    const weightedXQ3 = weightedXQ2;
    const weightedYQ3 = weightedYQ4;

    const weightedZQ3 = Quadrant.thirdQuadrant.formulaZ(
      weightedXQ3,
      weightedYQ3
    );
    return { weightedXQ3, weightedYQ3, weightedZQ3 };
  };

  const calcQ4Typ3 = () => {
    const weightedXQ4 = sumUpXValuesWithFactor(workingLinesQ4);
    const weightedZQ4 = sumUpZValuesWithFactor(workingLinesQ4);

    const weightedYQ4 = Quadrant.fourthQuadrant.formulaY(
      weightedXQ4,
      weightedZQ4
    );
    return { weightedXQ4, weightedYQ4, weightedZQ4 };
  };

  function preCheck() {
    if (
      workingLinesQ1.length !== workingLinesQ2.length ||
      workingLinesQ2.length !== workingLinesQ3.length ||
      workingLinesQ3.length !== workingLinesQ4.length
    ) {
      sendMessage("Warning: Number of working lines are not equal.");
      return false;
    }

    if (userProjects[projectId]?.areXAxisCongruent) {
      for (let index = 0; index < workingLinesQ1.length; index += 1) {
        if (
          workingLinesQ2[index].offsetX !== workingLinesQ3[index].offsetX ||
          workingLinesQ1[index].offsetX !== workingLinesQ4[index].offsetX ||
          workingLinesQ2[index].offsetY !== workingLinesQ1[index].offsetY ||
          workingLinesQ3[index].offsetY !== workingLinesQ4[index].offsetY
        ) {
          sendMessage("Warning: Working lines are not closed.");
          return false;
        }
      }
    } else {
      for (let index = 0; index < workingLinesQ1.length; index += 1) {
        if (
          workingLinesQ2[index].offsetX !== workingLinesQ3[index].offsetX ||
          workingLinesQ2[index].offsetY !== workingLinesQ1[index].offsetY ||
          workingLinesQ3[index].offsetY !== workingLinesQ4[index].offsetY
        ) {
          sendMessage("Warning: Working lines are not closed.");
          return false;
        }
      }
    }

    return true;
  }

  const calculateCombinedWorkingLine = (method) => {
    workingLinesQ1 = Quadrant.firstQuadrant.workingLines;
    workingLinesQ2 = Quadrant.secondQuadrant.workingLines;
    workingLinesQ3 = Quadrant.thirdQuadrant.workingLines;
    workingLinesQ4 = Quadrant.fourthQuadrant.workingLines;

    if (!selectedWorkingLines || selectedWorkingLines.length === 0) {
      sendMessage("Warning: No working line selected.");
      return;
    }

    if (
      typeof workingLinesQ1 === "undefined" ||
      typeof workingLinesQ2 === "undefined" ||
      typeof workingLinesQ3 === "undefined" ||
      typeof workingLinesQ4 === "undefined" ||
      workingLinesQ1.length < 2 ||
      workingLinesQ2.length < 2 ||
      workingLinesQ3.length < 2 ||
      workingLinesQ4.length < 2
    ) {
      sendMessage("Warning: The number of working lines is smaller then 2.");
      return;
    }

    if (!preCheck()) return;

    let xQ1 = 0;
    let yQ1 = 0;
    let xQ2 = 0;
    let yQ2 = 0;
    let xQ3 = 0;
    let yQ3 = 0;
    let xQ4 = 0;
    let yQ4 = 0;

    let color;
    const typeNumber = userProjects[projectId]?.typeNumber;

    if (
      typeNumber === "1_1" ||
      typeNumber === "1_2" ||
      typeNumber === "1_3" ||
      typeNumber === "1_4" ||
      typeNumber === "1_5"
    ) {
      if (method === 1) {
        color = "blue";
        const { weightedXQ4, weightedYQ4, weightedZQ4 } = calcQ4Variant1Typ1();
        const { weightedXQ3, weightedYQ3 } = calcQ3Variant1Typ1();
        const { weightedXQ1, weightedYQ1 } = calcQ1Variant1Typ1(
          weightedXQ4,
          weightedZQ4
        );
        const { weightedXQ2, weightedYQ2 } = calcQ2Variant1Typ1(
          weightedXQ3,
          weightedYQ1
        );

        xQ1 = weightedXQ1;
        xQ2 = weightedXQ2;
        xQ3 = weightedXQ3;
        xQ4 = weightedXQ4;

        yQ1 = weightedYQ1;
        yQ2 = weightedYQ2;
        yQ3 = weightedYQ3;
        yQ4 = weightedYQ4;
      } else {
        color = "red";
        const { weightedXQ3, weightedYQ3, weightedZQ3 } = calcQ3Variant2Typ1();
        const { weightedXQ4, weightedYQ4 } = calcQ4Variant2Typ1();
        const { weightedXQ2, weightedYQ2 } = calcQ2Variant2Typ1(
          weightedXQ3,
          weightedZQ3
        );
        const { weightedXQ1, weightedYQ1 } = calcQ1Variant2Typ1(
          weightedXQ4,
          weightedYQ2
        );

        xQ1 = weightedXQ1;
        xQ2 = weightedXQ2;
        xQ3 = weightedXQ3;
        xQ4 = weightedXQ4;

        yQ1 = weightedYQ1;
        yQ2 = weightedYQ2;
        yQ3 = weightedYQ3;
        yQ4 = weightedYQ4;
      }
    } else if (typeNumber === "3") {
      color = "blue";
      const { weightedXQ4, weightedYQ4 } = calcQ4Typ3();
      const { weightedXQ1, weightedYQ1 } = calcQ1Typ3();
      const { weightedXQ2, weightedYQ2 } = calcQ2Typ3(weightedXQ1, weightedYQ1);
      const { weightedXQ3, weightedYQ3 } = calcQ3Typ3(weightedXQ2, weightedYQ4);

      xQ1 = weightedXQ1;
      xQ2 = weightedXQ2;
      xQ3 = weightedXQ3;
      xQ4 = weightedXQ4;

      yQ1 = weightedYQ1;
      yQ2 = weightedYQ2;
      yQ3 = weightedYQ3;
      yQ4 = weightedYQ4;
    } else if (typeNumber === "4") {
      color = "blue";
      const { weightedXQ1, weightedYQ1 } = calcQ1Typ4();
      const { weightedXQ2, weightedYQ2 } = calcQ2Typ4(weightedXQ1, weightedYQ1);
      const { weightedXQ3, weightedYQ3 } = calcQ3Typ4(weightedXQ2, weightedXQ1);
      const { weightedXQ4, weightedYQ4 } = calcQ4Typ4(weightedYQ1, weightedYQ3);

      xQ1 = weightedXQ1;
      xQ2 = weightedXQ2;
      xQ3 = weightedXQ3;
      xQ4 = weightedXQ4;

      yQ1 = weightedYQ1;
      yQ2 = weightedYQ2;
      yQ3 = weightedYQ3;
      yQ4 = weightedYQ4;
    }

    if (!WorkingLine.checkRange(xQ1, yQ1, Quadrant.firstQuadrant)) {
      sendMessage(
        "Warning: Range check failed in Q1: " +
          "calculated working line can not be displayed. Please check range of x/y axis. "
      );
      return;
    }
    if (!WorkingLine.checkRange(xQ2, yQ2, Quadrant.secondQuadrant)) {
      sendMessage(
        "Warning: Range check failed in Q2: " +
          "calculated working line can not be displayed. Please check range of x/y axis. "
      );
      return;
    }
    if (!WorkingLine.checkRange(xQ3, yQ3, Quadrant.thirdQuadrant)) {
      sendMessage(
        "Warning: Range check failed in Q3: " +
          "calculated working line can not be displayed. Please check range of x/y axis. "
      );
      return;
    }

    if (!WorkingLine.checkRange(xQ4, yQ4, Quadrant.fourthQuadrant)) {
      sendMessage(
        "Warning: Range check failed in Q4: " +
          "calculated working line can not be displayed. Please check range of x/y axis. "
      );
      return;
    }

    const workingLine3 = new WorkingLine(
      Quadrant.thirdQuadrant,
      undefined,
      undefined,
      xQ3,
      yQ3,
      true,
      "Combined Working Line",
      color,
      3,
      0
    );

    const workingLine2 = new WorkingLine(
      Quadrant.secondQuadrant,
      undefined,
      undefined,
      xQ2,
      yQ2,
      true,
      "Combined Working Line",
      color,
      3,
      0
    );

    const workingLine4 = new WorkingLine(
      Quadrant.fourthQuadrant,
      undefined,
      undefined,
      xQ4,
      yQ4,
      true,
      "Combined Working Line",
      color,
      3,
      0
    );

    const workingLine1 = new WorkingLine(
      Quadrant.firstQuadrant,
      undefined,
      undefined,
      xQ1,
      yQ1,
      true,
      "Combined Working Line",
      color,
      3,
      0
    );

    workingLine1.isCombined = true;
    workingLine2.isCombined = true;
    workingLine3.isCombined = true;
    workingLine4.isCombined = true;

    /* workingLine1.putInLocalStorage = false;
    workingLine2.putInLocalStorage = false;
    workingLine3.putInLocalStorage = false;
    workingLine4.putInLocalStorage = false; */

    Quadrant.firstQuadrant.workingLines.push(workingLine1);
    Quadrant.secondQuadrant.workingLines.push(workingLine2);
    Quadrant.thirdQuadrant.workingLines.push(workingLine3);
    Quadrant.fourthQuadrant.workingLines.push(workingLine4);

    storeWorkingLinesInLocalStorage(Quadrant.firstQuadrant);
    storeWorkingLinesInLocalStorage(Quadrant.secondQuadrant);
    storeWorkingLinesInLocalStorage(Quadrant.thirdQuadrant);
    storeWorkingLinesInLocalStorage(Quadrant.fourthQuadrant);

    const newUserProjects = { ...userProjects };

    const storableLinesQ1 = Array.from(
      extractDrawingInfos(Quadrant.firstQuadrant.workingLines)
    );
    const storableLinesQ2 = Array.from(
      extractDrawingInfos(Quadrant.secondQuadrant.workingLines)
    );
    const storableLinesQ3 = Array.from(
      extractDrawingInfos(Quadrant.thirdQuadrant.workingLines)
    );
    const storableLinesQ4 = Array.from(
      extractDrawingInfos(Quadrant.fourthQuadrant.workingLines)
    );

    newUserProjects[projectId].quadrants[0].workingLines = storableLinesQ1;
    newUserProjects[projectId].quadrants[1].workingLines = storableLinesQ2;
    newUserProjects[projectId].quadrants[2].workingLines = storableLinesQ3;
    newUserProjects[projectId].quadrants[3].workingLines = storableLinesQ4;

    setUserProjects(newUserProjects);
  };

  const subscribe = useCallback(() => {
    if (subject === null) {
      subject = getWorkingLineCalculationSubject();
      subject.subscribe((method) => calculateCombinedWorkingLine(method));
    }
  }, []);

  getSelectedWorkingLines().subscribe((array) => {
    selectedWorkingLines = array;
  });

  useEffect(() => {
    subscribe();
    return () => {
      subject.unsubscribe();
    };
  }, [subject, subscribe]);
}
