import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SvgLogo from "../../../icons/QuadrantenMenue.svg";
import PngLogo from "../../../icons/QuadrantenMenue.png";
import { useData } from "../../DataProvider";

const useStyles = makeStyles({
  box: {
    display: "flex",
    alignItems: "center",
  },
  setPositionToLeft: {
    justifyContent: "unset",
  },

  button: {
    width: "30px",
    padding: 0,
    margin: 0,
    background: "none",
    color: "inherit",
    border: "none",
    cursor: "pointer",
  },
});

export default function ContextMenuLabelRightSide({
  qNumber,
  name,
  handleClick,
}) {
  const classes = useStyles();
  const { setHasIconsReplaced, hasIconReplaced, isOnSaving } = useData();

  useEffect(() => {
    if (isOnSaving && !hasIconReplaced) {
      setTimeout(() => setHasIconsReplaced(true), 50);
    }
  }, [hasIconReplaced, isOnSaving, setHasIconsReplaced]);

  return (
    <div className={`${classes.box} ${classes.setPositionToLeft}`}>
      <button
        id={`ContextMenuButtonRightSide_q${qNumber}`}
        type="button"
        className={classes.button}
        onClick={handleClick}
      >
        <img
          id={`image_logo_${qNumber}`}
          src={isOnSaving ? PngLogo : SvgLogo}
          style={{ maxWidth: "25px" }}
          width="30px"
          alt="diagramIcon"
        />
      </button>
      <span id={`ContextMenuLabelRightSide_q${qNumber}`}>{name}</span>
    </div>
  );
}
