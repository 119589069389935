import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp14(x, y) {
  return safeDivByZero(y, x) * 1000;
}

export function quadrant2zTyp14(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant3zTyp14(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp14(x, y) {
  return safeDivByZero(x, y);
}
