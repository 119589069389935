import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { receiveMessage } from "../subjects/general";

export default function Toast() {
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;
  const [errorMessage, setErrorMessage] = useState("");

  function handleClick(newState) {
    setState({ open: true, ...newState });
  }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  receiveMessage().subscribe((message) => {
    setErrorMessage(message);
    handleClick({ vertical: "top", horizontal: "right" });
  });

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={errorMessage}
        key={vertical + horizontal}
      />
    </div>
  );
}
