import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useData } from "../../DataProvider";
import PlaneAccordion from "./PlaneAccordion";
import { removeArrayItem } from "../../../helpers/util";
import EditableTextField from "../../ui-elements/EditableTextField";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const PlaneContent = forwardRef((props, ref) => {
  const classes = useStyles();
  const {
    setSelectedPlaneItem,
    setSelectedPlane,
    selectedPlane,
    planeItems,
    selectedPlaneItem,
    setPlaneItems,
    planeTitle,
    setPlaneTitle,
    accordionStates,
    setAccordionStates,
  } = useData();

  const { t } = useTranslation();

  const removePlaneItem = (itemId) => {
    let found;
    let i;
    for (i = 0; i < planeItems.length; i += 1) {
      if (typeof planeItems[i] !== "undefined") {
        found = planeItems[i].find((el) => el.id === itemId);
        if (found) break;
      }
    }
    if (found) {
      removeArrayItem(planeItems[i], found);
      setPlaneItems([...planeItems]);
    }
  };

  const removePlane = () => {
    let newAccordionStates = [...accordionStates];
    if (accordionStates.length === 1 || selectedPlane === 1) return;
    if (selectedPlane) {
      newAccordionStates = accordionStates.filter(
        (el) => el.id !== selectedPlane
      );

      if (typeof planeItems[selectedPlane - 1] !== "undefined") {
        const copyArrayForDeleting = [...planeItems[selectedPlane - 1]];
        copyArrayForDeleting.forEach((item) => removePlaneItem(item.id));
      }
    } else {
      newAccordionStates.pop();
    }
    setPlaneItems([...planeItems]);
    setAccordionStates(newAccordionStates);
  };

  const nextAccordionId = useCallback(() => {
    return accordionStates.reduce((acc, next) => Math.max(next.id, acc), 0) + 1;
  }, [accordionStates]);

  const updateTitle = useCallback(
    (id, value) => {
      const newAccordionState = [...accordionStates];
      const index = accordionStates.findIndex((el) => el.id === id);
      newAccordionState[index] = {
        ...accordionStates[index],
        inputValue: value,
      };
      setAccordionStates(newAccordionState);
    },
    [accordionStates, setAccordionStates]
  );

  const addElement = () => {
    const nextId = nextAccordionId();
    const newAccordionStates = [...accordionStates];
    newAccordionStates.push({
      id: nextId,
      inputValue: `${t("Ebene")} ${nextId}`,
    });
    setAccordionStates(newAccordionStates);
  };

  useImperativeHandle(ref, () => ({
    addItem() {
      addElement();
    },
    removeItem() {
      if (selectedPlaneItem) removePlaneItem(selectedPlaneItem);
      else removePlane();
    },
  }));

  function unsetSelectedItems() {
    setSelectedPlaneItem(undefined);
    setSelectedPlane(undefined);
  }

  if (accordionStates.length === 0) addElement();

  return (
    <ClickAwayListener onClickAway={unsetSelectedItems}>
      <div className={classes.root}>
        <EditableTextField
          value={planeTitle}
          onChange={(value) => setPlaneTitle(value)}
        />
        {accordionStates.map(({ id, inputValue }) => (
          <PlaneAccordion
            key={id}
            id={id}
            title={inputValue}
            updateTitle={updateTitle}
          />
        ))}
      </div>
    </ClickAwayListener>
  );
});

export default PlaneContent;
