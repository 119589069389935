import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PropTypes } from "prop-types";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Snackbar from "@material-ui/core/Snackbar";
import FileUploadPage from "./FileUploadPage";
import { useData } from "../components/DataProvider";
import { checkValidation } from "../services/api";
import Header from "../components/header/Header";

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: "center",
  },
  header: {
    color: theme.palette.primary.main,
    marginTop: 25,
    marginBottom: 70,
    fontSize: "xxx-large",
  },
  subHeader: {
    color: theme.palette.primary.main,
    marginTop: 60,
  },
  button: {
    borderRadius: 12,
    minWidth: 230,
    margin: 10,
  },
  divider: {
    marginTop: 60,
    fontSize: 25,
    color: theme.palette.primary.main,
  },
  textField: {
    width: 600,
    height: 250,
  },
}));

export default function WelcomePage() {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { userProjects } = useData();
  const classes = useStyles();
  const [valid, setValid] = useState(false);
  const [open, setOpen] = useState(false);
  const [li, setLicense] = useState(null);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("token"));

    async function isValid() {
      try {
        const { license } = await checkValidation(token);
        console.log(license);
        setLicense(license);
        if (Date.now() < new Date(license.end)) {
          setValid(true);
        } else {
          setOpen(true);
        }
      } catch (e) {
        console.log(e);
      }
    }
    isValid();
  }, []);

  return (
    <div>
      <Header isChart />

      <Grid container className={classes.center}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <h1 className={classes.header}>{t("Willkommen")}</h1>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => push("/project?new=true")}
            disabled={!valid}
          >
            {t("Erstelle_Projekt")}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => push("/project")}
            disabled={!userProjects || !valid}
          >
            {t("Open_Existing_Project")}
          </Button>
          <Divider>{t("Oder")}</Divider>
          <h2 className={classes.subHeader}>{t("Oeffne_Projekt")}</h2>
          <FileUploadPage />
          <Button onClick={() => push("/login")}>Zurück</Button>
        </Grid>
        <Grid item xs={4} />
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
        message={`${t("license")} ${new Date(
          li ? li.end : ""
        ).toLocaleString()}`}
        autoHideDuration={150000}
      />
    </div>
  );
}

function Divider({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.divider}>
      <div className="container">
        <div className="border" />
        <span className="content">{children}</span>
        <div className="border" />
      </div>
    </div>
  );
}

Divider.propTypes = {
  children: PropTypes.node.isRequired,
};
