import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp16(x, z) {
  return (x * z) / 1000;
}

export function quadrant2yTyp16(x, z) {
  return x * z;
}

export function quadrant3yTyp16(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp16(x, z) {
  return safeDivByZero(x, z);
}
