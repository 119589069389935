import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp10(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp10(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant3zTyp10(x, y) {
  return x * y;
}

export function quadrant4zTyp10(x, y) {
  return safeDivByZero(x, y);
}
