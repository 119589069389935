export function quadrant1xTyp5(y, z) {
  return y * z;
}

export function quadrant2xTyp5(y, z) {
  return z - y;
}

export function quadrant3xTyp5(y, z) {
  return y * z;
}

export function quadrant4xTyp5(y, z) {
  return y * z;
}
