import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { DialogActions, DialogContent } from "@material-ui/core";
import PDFRenderer from "./PDFRenderer";
import { useData } from "../../DataProvider";
import { localStorageHasItem } from "../../../helpers/util";
import UseSVGHelper from "../../hooks/useSVGhelper";

export default ({ close }) => {
  const onlySimplePage = localStorageHasItem("pdfMode");

  const { t } = useTranslation();
  const { userProjects, projectId } = useData();

  const downloadSVGImages = async () => {
    const images = await UseSVGHelper(userProjects[projectId]);

    const svgBlob = new Blob([images[0]], {
      type: "image/svg+xml;charset=utf-8",
    });
    const svgUrl = URL.createObjectURL(svgBlob);

    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = `Image_BIG.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const loadImage = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", (err) => reject(err));
      img.src = url;
    });

  // resolution (5760x2409)
  const downloadPNGImage = async () => {
    const images = await UseSVGHelper(userProjects[projectId]);

    const svgBlob = new Blob([images[0]], {
      type: "image/svg+xml;charset=utf-8",
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    const _img = await loadImage(svgUrl);
    const imgH = _img.naturalHeight * 3;
    const imgW = _img.naturalWidth * 3;
    const scale = window.devicePixelRatio * 2;

    const _canvas = document.createElement("canvas");
    _canvas.width = imgW;
    _canvas.height = imgH;
    const _ctx = _canvas.getContext("2d");

    _canvas["style"]["width"] = `${Math.round(imgW / scale)}px`;
    _canvas["style"]["height"] = `${Math.round(imgH / scale)}px`;
    _canvas["style"]["margin"] = "0";
    _canvas["style"]["padding"] = "0";
    _ctx.scale(scale, scale);
    _ctx.fillStyle = "white";
    _ctx.fillRect(0, 0, _canvas.width, _canvas.height);
    _ctx.drawImage(
      _img,
      0,
      0,
      Math.round(imgW / scale),
      Math.round(imgH / scale)
    );

    const downloadLink = document.createElement("a");
    downloadLink.href = _canvas.toDataURL();
    downloadLink.download = `Image_BIG.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Dialog onClose={close} open>
      <DialogTitle>
        {onlySimplePage ? t("imageDialogTitle") : t("pdfDialogTitle")}
      </DialogTitle>
      <DialogContent>
        {onlySimplePage ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button onClick={downloadSVGImages}>{t("SVG")}</Button>
            <Button onClick={downloadPNGImage}>{t("PNG")}</Button>
            <Button>
              <PDFRenderer
                fileName={`${userProjects[projectId].title}.pdf`}
                closeCreator={() => close}
                exportSinglePagePDF
              />
            </Button>
          </div>
        ) : (
          <PDFRenderer
            fileName={`${userProjects[projectId].title}.pdf`}
            closeCreator={() => close}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          {t("SaveDialog_dialog_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
