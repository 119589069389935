import React, { useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PropTypes from "prop-types";
import { Button, DialogActions, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import PlaneContent from "../levels/PlaneContent";
import { triggerAdditionalWorkingLineCalculation } from "../../subjects/d3Subjects";
import { isCombinedWorkingLineVariant1Possible } from "../../../helpers/util";
import { useData } from "../../DataProvider";

const PlanesDialog = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(true);
  const {
    data,
    projectId,
    userProjects,
    accordionStates,
    planeItems,
    topPlane,
    setUserProjects,
    getUserProjectsLS,
    planeTitle,
  } = useData();

  const useStyles = makeStyles(() => ({
    dialog: {
      position: "absolute",
      left: ref.current.getBoundingClientRect().width,
      top: ref.current.getBoundingClientRect().height + 35,
    },
  }));
  const classes = useStyles();
  const childRef = useRef();
  const { close } = props;
  const { t } = useTranslation();
  let buttonTextLeft = "";
  let buttonTextRight = "";
  const typeNumber = userProjects[projectId]?.typeNumber;

  console.log(typeNumber);

  if (
    typeNumber === "1_1" ||
    typeNumber === "1_2" ||
    typeNumber === "1_3" ||
    typeNumber === "1_4" ||
    typeNumber === "1_5"
  ) {
    buttonTextLeft = t("Variante1_Header_Text_Typ_1");
    buttonTextRight = t("Variante2_Header_Text_Typ_1");
  } else if (typeNumber === "3") {
    buttonTextLeft = t("Variante1_Header_Text_Typ_3");
  } else if (typeNumber === "4") {
    buttonTextLeft = t("Variante1_Header_Text_Typ_4");
  }

  const updateVisibilityOfWorkingLines = (planeItems, userProject) => {
    userProject.quadrants.forEach((quadrant) => {
      quadrant.workingLines.forEach((line) => {
        const item = planeItems
          .flatMap((el) => el)
          .find((el) => +el.id.split("_").pop() === line.sequenceNumber);
        if (item) line.isVisible = item.isVisible;
      });
    });
  };

  const savePlanesConfig = () => {
    const newPlaneItems = planeItems.map((planeItem) =>
      planeItem.map((el) => ({
        ...el,
        weightFactor: el.weightFactor === "" ? 1 : el.weightFactor,
      }))
    );
    const userProjectsLocalStorage = getUserProjectsLS();
    userProjectsLocalStorage[projectId].planeConfig.planeItems = newPlaneItems;
    userProjectsLocalStorage[projectId].planeConfig.topPlane = +topPlane;
    userProjectsLocalStorage[projectId].planeConfig.numberOfAccordions =
      +accordionStates.length;
    userProjectsLocalStorage[projectId].planeConfig.title = planeTitle;
    userProjectsLocalStorage[projectId].planeConfig.accordionStates =
      accordionStates;
    updateVisibilityOfWorkingLines(
      newPlaneItems,
      userProjectsLocalStorage[projectId]
    );
    setUserProjects({ ...userProjectsLocalStorage });
  };

  const handleClose = () => {
    savePlanesConfig();
    setOpen(false);
    close();
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="lg"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classes.dialog,
        }}
      >
        <DialogContent>
          <PlaneContent ref={childRef} />
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              onClick={() => {
                handleClose();
                triggerAdditionalWorkingLineCalculation(1);
              }}
              disabled={
                !isCombinedWorkingLineVariant1Possible(
                  data.userProjects[projectId]
                )
              }
            >
              {buttonTextLeft}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                triggerAdditionalWorkingLineCalculation(2);
              }}
              disabled={
                !isCombinedWorkingLineVariant1Possible(
                  data.userProjects[projectId]
                )
              }
            >
              {buttonTextRight}
            </Button>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              aria-label="add"
              onClick={() => childRef.current.addItem()}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => childRef.current.removeItem()}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
});

PlanesDialog.propTypes = {
  close: PropTypes.func.isRequired,
};

export default PlanesDialog;
