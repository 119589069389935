import React from "react";
import { Snackbar } from "@material-ui/core/";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

export default function ToastAlert(props) {
  const { msg, open, setOpen } = props;
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleClose}>
          <AlertTitle>
            <strong>{t("Project_saved")}</strong>
          </AlertTitle>
          {msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
