import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { PropTypes } from "prop-types";
import { ColorPicker, useColor } from "react-color-palette";
import { useTranslation } from "react-i18next";
import "react-color-palette/lib/css/styles.css";

export default function ColorPickerDialog({ show, currentColor, close }) {
  const [open, setOpen] = React.useState(show);
  const [color, setColor] = useColor("hex", currentColor);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    close(color.hex);
  };

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {t("Farbendialog_Header")}
        </DialogTitle>
        <DialogContent>
          <ColorPicker
            width={456}
            height={228}
            color={color}
            onChange={setColor}
            hideHSV
            hideHEX
            hideRGB
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Schliessen")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

ColorPickerDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  currentColor: PropTypes.string,
  close: PropTypes.func.isRequired,
};

ColorPickerDialog.defaultProps = {
  currentColor: undefined,
};
