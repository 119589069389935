import { Subject } from "rxjs";

const selectedWorkingLinesSubject$ = new Subject();

export function setSelectedWorkingLines(obj) {
  selectedWorkingLinesSubject$.next(obj);
}

export function getSelectedWorkingLines() {
  return selectedWorkingLinesSubject$;
}
