import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DiagramTypeContent from "./DiagramTypeContent";

export default function DiagramTypeDialog({ onClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      open
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">
        {t("Diagramm_text")}
      </DialogTitle>
      <DialogContent>
        <DiagramTypeContent />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("Schliessen")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DiagramTypeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
