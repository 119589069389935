import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useData } from "../DataProvider";

export default function SaveDialog({ fileName, onClose }) {
  const { setFileName } = useData();
  const [value, setValue] = useState(fileName);
  const { t } = useTranslation();
  const ABORT = 0;
  const SUCCESS = 1;

  const handleClose = () => {
    setFileName(value);
    onClose(SUCCESS, value);
  };

  return (
    <div>
      <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t("SaveDialog_dialog_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t("SaveDialog_dialog_label")}
            type="email"
            fullWidth
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(ABORT)} color="primary">
            {t("SaveDialog_dialog_abort")}
          </Button>
          <Button onClick={handleClose} color="primary">
            {t("SaveDialog_dialog_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SaveDialog.propTypes = {
  fileName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
