import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp18(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant2xTyp18(y, z) {
  return y * z;
}

export function quadrant3xTyp18(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant4xTyp18(y, z) {
  return safeDivByZero(y, z);
}
