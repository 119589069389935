import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp17(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant2yTyp17(x, z) {
  return x * z;
}

export function quadrant3yTyp17(x, z) {
  return x * z;
}

export function quadrant4yTyp17(x, z) {
  return 60 * x * z;
}
