import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp1(x, y) {
  return x * y;
}

export function quadrant2zTyp1(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant3zTyp1(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant4zTyp1(x, y) {
  return x * y;
}
