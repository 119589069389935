/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({ component: Component, path }) => {
  const token = localStorage.getItem("token");

  if (token) {
    return <Route component={Component} path={path} />;
  }
  return <Redirect to="/login" />;
};

export default ProtectedRoute;
