import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp9(y, z) {
  return (y * 1000) / z;
}

export function quadrant2xTyp9(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant3xTyp9(y, z) {
  return y + z;
}

export function quadrant4xTyp9(y, z) {
  return y - z;
}
