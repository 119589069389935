import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import numeral from "numeral";
import TreeComponent from "./DataTreeComponent";
import iconPrimary from "../../icons/Menue_Background_blue.svg";
import iconPrimaryPng from "../../images/Menue.png";
import iconSecondary from "../../icons/Menue_blue_withoutBackground.svg";
import { useData } from "../DataProvider";
import "numeral/locales/de";
import "numeral/locales/en-gb";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
  header: {
    color: "#0064a7",
    textAlign: "left",
    font: "normal normal bold 25px/30px Helvetica",
    letterSpacing: "0px",
    textTransform: "uppercase",
    opacity: "1",
    marginTop: "30px",
  },
  subHeader: {
    textAlign: "left",
    font: "normal normal bold 18px/21px Helvetica",
    letterSpacing: "0px",
    color: "#78AACC",
    opacity: "1",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "1px",
  },
  projects: {
    marginTop: "135px",
  },
  linksPrimaryColor: {
    color: "#0064A7",
  },
  linksSecondaryColor: {
    color: "#78AACC",
  },
  sidePanel: {
    marginLeft: "59px",
  },
  btn: {
    backgroundColor: "transparent",
    border: "none",
  },
  formControl: {
    minWidth: 120,
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const anchorName = "left";

export default function NavigationBar() {
  const classes = useStyles();
  const [state, setState] = React.useState({ left: false });
  const [iconState, setIconState] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = React.useState(i18n.language || "en");
  const { userProjects, setLocation, isOnSaving } = useData();
  numeral.localeData("de").delimiters.thousands = ".";

  const projectStructure = [
    {
      id: uuid(),
      name: t("Diagramme"),
      children: [],
    },
  ];

  const addElementToFolderStructure = (name) => {
    const child = {
      id: uuid(),
      name,
    };
    projectStructure[0].children.push(child);
  };

  if (userProjects !== null) {
    Object.entries(userProjects).forEach((project) => {
      const { title } = project[1];
      addElementToFolderStructure(title);
    });
  }

  const handleChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    setLang(value);
    if (value === "en") numeral.locale("en-gb");
    else if (value === "de") numeral.locale("de");
    setLocation(value);
    localStorage.setItem("langTriggered", "true");
    localStorage.setItem("i18nextLng", value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setIconState(!iconState);
  };

  const list = () => (
    <div className={clsx(classes.list)}>
      <div className={classes.sidePanel}>
        <div className={classes.header}>
          {localStorage.getItem("projectName")}
        </div>
        <br />

        <div className={classes.subHeader}>
          <Link to="/project" className={classes.subHeader}>
            {t("Uebersicht")}
          </Link>
        </div>
        <div className={classes.subHeader}>
          <Link to="/openProject" className={classes.subHeader}>
            {t("Nav_Projekt_oeffnen")}
          </Link>
        </div>
        <div className={classes.subHeader}>
          <TreeComponent data={projectStructure} />
        </div>
      </div>

      <div
        className={classes.sidePanel}
        style={{
          position: "fixed",
          bottom: 0,
          width: 100,
        }}
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">
            {t("Nav_Sprache")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={lang}
            onChange={handleChange}
          >
            <MenuItem value="de">{t("Deutsch")}</MenuItem>
            <MenuItem value="en">{t("Englisch")}</MenuItem>
          </Select>
        </FormControl>
        {/*  <div className={classes.subHeader}>
          <Link to="/help" className={classes.subHeader}>
            {t("Nav_Hilfe")}
          </Link>
        </div>
        <div className={classes.subHeader}>
          <Link to="/about" className={classes.subHeader}>
            {t("Nav_Ueber")}
          </Link>
        </div> */}
      </div>
    </div>
  );

  const getSource = () => {
    if (isOnSaving) return iconPrimaryPng;
    if (iconState) return iconPrimary;
    return iconSecondary;
  };

  return (
    <>
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor={anchorName}
        open={state[anchorName]}
        onClose={toggleDrawer(anchorName, false)}
      >
        {list()}
      </Drawer>

      <button
        type="button"
        className={clsx(classes.btn)}
        onClick={toggleDrawer(anchorName, true)}
      >
        <img src={getSource()} alt="shows menu button" width="48px" />
      </button>
    </>
  );
}
