export function quadrant1xTyp2(y, z) {
  return y * z;
}

export function quadrant2xTyp2(y, z) {
  return y - z;
}

export function quadrant3xTyp2(y, z) {
  return z - y;
}

export function quadrant4xTyp2(y, z) {
  return y * z;
}
