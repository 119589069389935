import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp17(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp17(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant3zTyp17(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant4zTyp17(x, y) {
  return safeDivByZero(y, x * 60);
}
