import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp17(y, z) {
  return y * z;
}

export function quadrant2xTyp17(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant3xTyp17(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant4xTyp17(y, z) {
  return safeDivByZero(y, 60 * z);
}
