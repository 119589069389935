import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp8(y, z) {
  return y * z;
}

export function quadrant2xTyp8(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant3xTyp8(y, z) {
  return y + z;
}

export function quadrant4xTyp8(y, z) {
  return y - z;
}
