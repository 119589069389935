import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp8(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp8(x, y) {
  return x * y;
}

export function quadrant3zTyp8(x, y) {
  return x - y;
}

export function quadrant4zTyp8(x, y) {
  return y - x;
}
