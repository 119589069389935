import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp1(x, z) {
  return safeDivByZero(z, x);
}

export function quadrant2yTyp1(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant3yTyp1(x, z) {
  return z * x;
}

export function quadrant4yTyp1(x, z) {
  return safeDivByZero(z, x);
}
