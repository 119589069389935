import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp15(x, y) {
  return safeDivByZero(y, x) * 100;
}

export function quadrant2zTyp15(x, y) {
  return safeDivByZero(x, y) * 1000;
}

export function quadrant3zTyp15(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp15(x, y) {
  return safeDivByZero(x, y);
}
