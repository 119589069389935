import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp6(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant2xTyp6(y, z) {
  // return safeDivByZero(100 * y, 100 - z);
  return safeDivByZero(y, 1 - z / 100);
}

export function quadrant3xTyp6(y, z) {
  return y * z;
}

export function quadrant4xTyp6(y, z) {
  return safeDivByZero(y, z);
}
