import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp8(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant2yTyp8(x, z) {
  return safeDivByZero(z, x);
}

export function quadrant3yTyp8(x, z) {
  return x - z;
}

export function quadrant4yTyp8(x, z) {
  return x + z;
}
