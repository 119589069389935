import { Subject } from "rxjs";

const localStorageRead$ = new Subject();
const localStorageWrite$ = new Subject();

export function onLocalStorageReadChange() {
  localStorageRead$.next();
}

export function localStorageReadListener() {
  return localStorageRead$;
}

export function onLocalStorageWriteChange() {
  localStorageWrite$.next();
}

export function localStorageWriteListener() {
  return localStorageWrite$;
}
