export const loginUser = async (email, password) => {
  return fetch(`${process.env.REACT_APP_URL}wp-json/jwt-auth/v1/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  }).then((body) => body.json());
};

export const checkValidation = async (token) => {
  return fetch(`${process.env.REACT_APP_URL}wp-json/interakt/v1/user/license`, {
    method: "GET",
    headers: {
      JWTAuthorization: `Bearer ${token}`,
    },
  }).then((body) => body.json());
};
