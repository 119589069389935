import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp5(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant2zTyp5(x, y) {
  return y + x;
}

export function quadrant3zTyp5(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp5(x, y) {
  return safeDivByZero(x, y);
}
