import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { Menu, Tooltip } from "@material-ui/core";
import DiagramConfigDialog from "./DiagramConfigDialog";
import {
  deleteAllWorkingLines,
  notifyExtendWorkingLineHorizontal,
  notifyExtendWorkingLineVertical,
  resetZoomTrigger,
  resetZoomTriggerAll,
  updateZoomTrigger,
} from "../../subjects/d3Subjects";
import ContextMenuLabelLeftSide from "./ContextMenuLabelLeftSide";
import ContextMenuLabelRightSide from "./ContextMenuLabelRightSide";

const useStyles = makeStyles({
  qText: {
    font: "normal normal bold 14px/16px Helvetica",
    letterSpacing: "0px",
    color: "#0064A7",
    textTransform: "uppercase",
    verticalAlign: "middle",
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  setPositionToLeft: {
    justifyContent: "unset",
    marginLeft: 40,
  },
  setPositionToRight: {
    justifyContent: "flex-end",
    marginRight: 80,
  },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: "white",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main,
      },
    },
  },
}))(MenuItem);

export default function ContextMenu({
  name,
  isOnLeftSide = false,
  qNumber,
  styles,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visible, setVisible] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const delay = 1000;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function closeDialog() {
    setVisible(false);
    handleClose();
  }

  const setUpQuadrantAutomatically = () => 0;

  const extendWorkingLineHorizontal = () => {
    notifyExtendWorkingLineHorizontal(qNumber);
  };

  const extendWorkingLineVertical = () => {
    notifyExtendWorkingLineVertical(qNumber);
  };

  const resetZoomAllQuadrants = () => {
    resetZoomTriggerAll();
  };

  const resetZoom = () => {
    resetZoomTrigger(qNumber);
  };

  const updateZoomLevel = () => {
    updateZoomTrigger(qNumber);
  };

  const deleteWorkingLines = () => {
    deleteAllWorkingLines(qNumber);
  };

  const handleMenuItemClick = (event) => {
    const { myValue } = event.currentTarget.dataset;

    switch (myValue) {
      case "1":
        setUpQuadrantAutomatically();
        break;
      case "3":
        extendWorkingLineHorizontal();
        break;
      case "4":
        extendWorkingLineVertical();
        break;
      case "6":
        resetZoomAllQuadrants();
        break;
      case "7":
        resetZoom();
        break;
      case "8":
        updateZoomLevel();
        break;
      case "9":
        deleteWorkingLines();
        break;
      default:
        break;
    }
    closeDialog();
  };

  return (
    <div className={classes.qText} style={styles}>
      {isOnLeftSide ? (
        <ContextMenuLabelLeftSide
          qNumber={qNumber}
          name={name}
          handleClick={handleClick}
        />
      ) : (
        <ContextMenuLabelRightSide
          qNumber={qNumber}
          name={name}
          handleClick={handleClick}
        />
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disableAutoFocusItem
      >
        <Tooltip
          title={t("Text_Diagramm_anpassen")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={10} onClick={() => setVisible(true)}>
            <ListItemText primary={t("Ueberschrift_Diagramm_anpassen")} />
          </StyledMenuItem>
        </Tooltip>

        <Tooltip
          title={t("Text_Arbeitslinie_fortsetzen_horizontal")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={3} onClick={handleMenuItemClick}>
            <ListItemText
              primary={t("Ueberschrift_Arbeitslinie_fortsetzen_horizontal")}
            />
          </StyledMenuItem>
        </Tooltip>

        <Tooltip
          title={t("Text_Arbeitslinie_fortsetzen_vertikal")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={4} onClick={handleMenuItemClick}>
            <ListItemText
              primary={t("Ueberschrift_Arbeitslinie_fortsetzen_vertikal")}
            />
          </StyledMenuItem>
        </Tooltip>

        <Tooltip
          title={t("Text_Zoom_zuruecksetzen_alle_Quadranten")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={6} onClick={handleMenuItemClick}>
            <ListItemText primary={t("Ueberschrift_Zoom_zuruecksetzen_alle")} />
          </StyledMenuItem>
        </Tooltip>

        <Tooltip
          title={t("Text_Zoom_zuruecksetzen")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={7} onClick={handleMenuItemClick}>
            <ListItemText primary={t("Ueberschrift_Zoom_zuruecksetzen")} />
          </StyledMenuItem>
        </Tooltip>
        <Tooltip
          title={t("Text_Alle_Arbeitslinien_loeschen")}
          enterNextDelay={delay}
          enterDelay={delay}
          placement="right"
        >
          <StyledMenuItem data-my-value={9} onClick={handleMenuItemClick}>
            <ListItemText
              primary={t("Ueberschrift_Alle_Arbeitslinien_loeschen")}
            />
          </StyledMenuItem>
        </Tooltip>
      </Menu>
      <DiagramConfigDialog
        isVisible={visible}
        close={closeDialog}
        qNumber={qNumber}
      />
    </div>
  );
}

ContextMenu.propTypes = {
  name: PropTypes.string.isRequired,
  isOnLeftSide: PropTypes.bool,
  qNumber: PropTypes.number.isRequired,
};

ContextMenu.defaultProps = {
  isOnLeftSide: false,
};
