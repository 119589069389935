import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp16(x, y) {
  return safeDivByZero(y, x) * 1000;
}

export function quadrant2zTyp16(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant3zTyp16(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp16(x, y) {
  return safeDivByZero(x, y);
}
