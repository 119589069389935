import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../icons/Info.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  fieldSet: {
    borderRadius: "25px",
    border: "1px solid #0064A7",
    padding: "20px",
    font: "normal normal normal 14px/16px Helvetica",
    margin: "25px",
  },
  textField: {
    font: "normal normal normal 14px/16px Helvetica",
    textAlign: "center",
    marginTop: 10,
    fontSize: "20px",
  },
  btn: {
    backgroundColor: "transparent",
    border: "none",
  },
}));

export default function LayoutTextFields({
  legendName,
  changeText,
  data,
  handleInput: updateValue,
  index,
}) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const keys = Object.keys(data)
    .filter((el) => el !== "xAxisLabel")
    .filter((el) => el !== "yAxisLabel")
    .filter((el) => !el.startsWith("helperText"));

  LayoutTextFields.propTypes = {
    legendName: PropTypes.string.isRequired,
    changeText: PropTypes.func.isRequired,
    data: PropTypes.shape({
      min: PropTypes.string,
      mainIntervall: PropTypes.string,
      numberIntervalls: PropTypes.string,
      helperIntervall: PropTypes.string,
      numberDecPoints: PropTypes.string,
      helperTextHeader: PropTypes.shape({
        min: PropTypes.string,
        mainIntervall: PropTypes.string,
        numberIntervalls: PropTypes.string,
        helperIntervall: PropTypes.string,
        numberDecPoints: PropTypes.string,
        general: PropTypes.string,
      }),
      helperTextBody: PropTypes.shape({
        min: PropTypes.string,
        mainIntervall: PropTypes.string,
        numberIntervalls: PropTypes.string,
        helperIntervall: PropTypes.string,
        numberDecPoints: PropTypes.string,
        general: PropTypes.string,
      }),
    }).isRequired,
    handleInput: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
  };

  const getLabel = (key) => {
    switch (key) {
      case "general":
        return t("Ueberschrift_Einstellungen");
      case "min":
        return t("Ueberschrift_Minimum");
      case "mainIntervall":
        return t("Ueberschrift_Hauptintervall");
      case "numberIntervalls":
        return t("Ueberschrift_AnzahlIntervalle");
      case "helperIntervall":
        return t("Hilfsintervall");
      case "numberDecPoints":
        return t("Ueberschrift_AnzahlDezimalPunkte");
      case "minDS":
        return t("Ueberschrift_Minimum_Datenserien");
      case "number":
        return t("Ueberschrift_Anzahl_Datenserien");
      case "steps":
        return t("Ueberschrift_Schritte");
      default:
        return key;
    }
  };

  const getHelperTextHeader = (key) => {
    switch (key) {
      case "general":
        return t("Ueberschrift_Einstellungen");
      case "min":
        return t("Ueberschrift_Minimum");
      case "mainIntervall":
        return t("Ueberschrift_Hauptintervall");
      case "numberIntervalls":
        return t("Ueberschrift_AnzahlIntervalle");
      case "helperIntervall":
        return t("Hilfsintervall");
      case "numberDecPoints":
        return t("Ueberschrift_AnzahlDezimalPunkte");
      case "minDS":
        return t("Ueberschrift_Minimum_Datenserien");
      case "number":
        return t("Ueberschrift_Anzahl_Datenserien");
      case "steps":
        return t("Ueberschrift_Schritte");
      default:
        return key;
    }
  };

  const getHelperTextBody = (key) => {
    switch (key) {
      case "general":
        return t("Text_Einstellungen");
      case "min":
        return t("Text_Minimum");
      case "mainIntervall":
        return t("Text_Hauptintervall");
      case "numberIntervalls":
        return t("Text_AnzahlIntervalle");
      case "helperIntervall":
        return t("Text_Hilfsintervall");
      case "numberDecPoints":
        return t("Text_AnzahlDezimalPunkte");
      case "minDS":
        return t("Text_Minimum_Datenserien");
      case "number":
        return t("Text_Anzahl_Datenserien");
      case "steps":
        return t("Text_Schritte");
      default:
        return key;
    }
  };

  const formatString = (value) => {
    if (i18n.language === "de") {
      return value.toString().replace(".", ",");
    }

    return value.toString().replace(",", ".");
  };

  const listOfTextFields = keys.map((key, idx) => (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <TextField
            id={`textField_${idx}`}
            label={getLabel(key)}
            value={formatString(data[key])}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              classes: {
                input: classes.textField,
              },
            }}
            onChange={(event) =>
              updateValue(event.target.value, key, data, index)
            }
            key={`textField${key}`}
          />
        </Grid>
        <Grid item xs={3}>
          <button
            type="button"
            className={classes.btn}
            onClick={() =>
              changeText(getHelperTextHeader(key), getHelperTextBody(key))
            }
            tabIndex={-1}
          >
            <img src={InfoIcon} alt="diagramIcon" width="100%" height="40px" />
          </button>
        </Grid>
      </Grid>
    </div>
  ));

  return (
    <section>
      <fieldset className={classes.fieldSet}>
        <legend>{legendName}</legend>
        <form className={classes.root} noValidate autoComplete="off">
          <div>{listOfTextFields}</div>
        </form>
      </fieldset>
    </section>
  );
}
