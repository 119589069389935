import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  IconButton,
  TextField,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
//
import DeleteIcon from "@material-ui/icons/Delete";
//
import TypeSelect from "../../../components/ui-elements/TypeSelect";

const SpecialAnnotation = (props) => {
  const { id, onDelete, onUpdate, selected, amount, fields } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSelectionChange = (change) => {
    onUpdate({
      id,
      selected: change.target.value,
      amount,
    });
  };

  const handleAmountChange = (change) => {
    onUpdate({
      id,
      selected,
      amount: change.target.value,
    });
  };

  return (
    <div className={classes.container}>
      <TypeSelect
        className={[classes.selection]}
        fields={fields}
        labelText={t("Spezialfeld_text")}
        onChange={handleSelectionChange}
        defaultValue={selected}
      />
      <FormControl className={[classes.formControl]}>
        <TextField
          className={[classes.textField]}
          inputProps={{ min: 0 }}
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue=""
          value={amount}
          onChange={handleAmountChange}
        />
        <FormHelperText>{t("Anzahl_eingeben")}</FormHelperText>
      </FormControl>
      <div>
        <IconButton aria-label="delete" onClick={() => onDelete(id)}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  formControl: {
    margin: 8,
    minWidth: 60,
    width: 180,
  },
  textField: {
    marginTop: 17,
  },
  selection: {
    width: 280,
  },
}));

export default SpecialAnnotation;
