import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp13(x, z) {
  return x * z;
}

export function quadrant2yTyp13(x, z) {
  return x * z;
}

export function quadrant3yTyp13(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp13(x, z) {
  return safeDivByZero(x, z);
}
