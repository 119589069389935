import { BehaviorSubject, Subject } from "rxjs";

const label$ = new Subject();
const textField$ = new Subject();
const snapping$ = new BehaviorSubject(false);
const lineSnapping$ = new BehaviorSubject(false);
const synchronousZooming$ = new BehaviorSubject(false);

export function setLabelStatus(status) {
  label$.next(status);
}

export function getLabelStatus() {
  return label$;
}

export function setSnappingStatus(status) {
  snapping$.next(status);
}

export function setLineSnappingStatus(status) {
  lineSnapping$.next(status);
}

export function getSnappingStatus() {
  return snapping$;
}

export function getLineSnappingStatus() {
  return lineSnapping$;
}

export function triggerTextField() {
  textField$.next();
}

export function getTriggerTextField() {
  return textField$;
}

export function setSynchronousZooming(status) {
  synchronousZooming$.next(status);
}

export function getSynchronousZooming() {
  return synchronousZooming$;
}
