import {
  quadrant1xTyp1,
  quadrant2xTyp1,
  quadrant3xTyp1,
  quadrant4xTyp1,
} from "../diagramTypes/typ1_1/x";
import {
  quadrant1yTyp1,
  quadrant2yTyp1,
  quadrant3yTyp1,
  quadrant4yTyp1,
} from "../diagramTypes/typ1_1/y";
import {
  quadrant1zTyp1,
  quadrant2zTyp1,
  quadrant3zTyp1,
  quadrant4zTyp1,
} from "../diagramTypes/typ1_1/z";
import {
  quadrant1xTyp2,
  quadrant2xTyp2,
  quadrant3xTyp2,
  quadrant4xTyp2,
} from "../diagramTypes/typ2/x";
import {
  quadrant1yTyp2,
  quadrant2yTyp2,
  quadrant3yTyp2,
  quadrant4yTyp2,
} from "../diagramTypes/typ2/y";
import {
  quadrant1zTyp2,
  quadrant2zTyp2,
  quadrant3zTyp2,
  quadrant4zTyp2,
} from "../diagramTypes/typ2/z";
import {
  quadrant1xTyp3,
  quadrant2xTyp3,
  quadrant3xTyp3,
  quadrant4xTyp3,
} from "../diagramTypes/typ3/x";
import {
  quadrant1yTyp3,
  quadrant2yTyp3,
  quadrant3yTyp3,
  quadrant4yTyp3,
} from "../diagramTypes/typ3/y";
import {
  quadrant1zTyp3,
  quadrant2zTyp3,
  quadrant3zTyp3,
  quadrant4zTyp3,
} from "../diagramTypes/typ3/z";
import {
  quadrant1xTyp4,
  quadrant2xTyp4,
  quadrant3xTyp4,
  quadrant4xTyp4,
} from "../diagramTypes/typ4/x";
import {
  quadrant1yTyp4,
  quadrant2yTyp4,
  quadrant3yTyp4,
  quadrant4yTyp4,
} from "../diagramTypes/typ4/y";
import {
  quadrant1zTyp4,
  quadrant2zTyp4,
  quadrant3zTyp4,
  quadrant4zTyp4,
} from "../diagramTypes/typ4/z";
import {
  quadrant1xTyp5,
  quadrant2xTyp5,
  quadrant3xTyp5,
  quadrant4xTyp5,
} from "../diagramTypes/typ5/x";
import {
  quadrant1yTyp5,
  quadrant2yTyp5,
  quadrant3yTyp5,
  quadrant4yTyp5,
} from "../diagramTypes/typ5/y";
import {
  quadrant1zTyp5,
  quadrant2zTyp5,
  quadrant3zTyp5,
  quadrant4zTyp5,
} from "../diagramTypes/typ5/z";
import {
  quadrant1xTyp6,
  quadrant2xTyp6,
  quadrant3xTyp6,
  quadrant4xTyp6,
} from "../diagramTypes/typ6/x";
import {
  quadrant1yTyp6,
  quadrant2yTyp6,
  quadrant3yTyp6,
  quadrant4yTyp6,
} from "../diagramTypes/typ6/y";
import {
  quadrant1zTyp6,
  quadrant2zTyp6,
  quadrant3zTyp6,
  quadrant4zTyp6,
} from "../diagramTypes/typ6/z";
import {
  quadrant1xTyp7,
  quadrant2xTyp7,
  quadrant3xTyp7,
  quadrant4xTyp7,
} from "../diagramTypes/typ7/x";
import {
  quadrant1yTyp7,
  quadrant2yTyp7,
  quadrant3yTyp7,
  quadrant4yTyp7,
} from "../diagramTypes/typ7/y";
import {
  quadrant1zTyp7,
  quadrant2zTyp7,
  quadrant3zTyp7,
  quadrant4zTyp7,
} from "../diagramTypes/typ7/z";
import {
  quadrant1xTyp8,
  quadrant2xTyp8,
  quadrant3xTyp8,
  quadrant4xTyp8,
} from "../diagramTypes/typ8/x";
import {
  quadrant1yTyp8,
  quadrant2yTyp8,
  quadrant3yTyp8,
  quadrant4yTyp8,
} from "../diagramTypes/typ8/y";
import {
  quadrant1zTyp8,
  quadrant2zTyp8,
  quadrant3zTyp8,
  quadrant4zTyp8,
} from "../diagramTypes/typ8/z";
import {
  quadrant1xTyp9,
  quadrant2xTyp9,
  quadrant3xTyp9,
  quadrant4xTyp9,
} from "../diagramTypes/typ9/x";
import {
  quadrant1yTyp9,
  quadrant2yTyp9,
  quadrant3yTyp9,
  quadrant4yTyp9,
} from "../diagramTypes/typ9/y";
import {
  quadrant1zTyp9,
  quadrant2zTyp9,
  quadrant3zTyp9,
  quadrant4zTyp9,
} from "../diagramTypes/typ9/z";
import {
  quadrant1xTyp10,
  quadrant2xTyp10,
  quadrant3xTyp10,
  quadrant4xTyp10,
} from "../diagramTypes/typ10/x";
import {
  quadrant1yTyp10,
  quadrant2yTyp10,
  quadrant3yTyp10,
  quadrant4yTyp10,
} from "../diagramTypes/typ10/y";
import {
  quadrant1zTyp10,
  quadrant2zTyp10,
  quadrant3zTyp10,
  quadrant4zTyp10,
} from "../diagramTypes/typ10/z";
import {
  quadrant1xTyp11,
  quadrant2xTyp11,
  quadrant3xTyp11,
  quadrant4xTyp11,
} from "../diagramTypes/typ11/x";
import {
  quadrant1yTyp11,
  quadrant2yTyp11,
  quadrant3yTyp11,
  quadrant4yTyp11,
} from "../diagramTypes/typ11/y";
import {
  quadrant1zTyp11,
  quadrant2zTyp11,
  quadrant3zTyp11,
  quadrant4zTyp11,
} from "../diagramTypes/typ11/z";
import {
  quadrant1xTyp12,
  quadrant2xTyp12,
  quadrant3xTyp12,
  quadrant4xTyp12,
} from "../diagramTypes/typ12/x";
import {
  quadrant1yTyp12,
  quadrant2yTyp12,
  quadrant3yTyp12,
  quadrant4yTyp12,
} from "../diagramTypes/typ12/y";
import {
  quadrant1zTyp12,
  quadrant2zTyp12,
  quadrant3zTyp12,
  quadrant4zTyp12,
} from "../diagramTypes/typ12/z";
import {
  quadrant1xTyp13,
  quadrant2xTyp13,
  quadrant3xTyp13,
  quadrant4xTyp13,
} from "../diagramTypes/typ13/x";
import {
  quadrant1yTyp13,
  quadrant2yTyp13,
  quadrant3yTyp13,
  quadrant4yTyp13,
} from "../diagramTypes/typ13/y";
import {
  quadrant1zTyp13,
  quadrant2zTyp13,
  quadrant3zTyp13,
  quadrant4zTyp13,
} from "../diagramTypes/typ13/z";
import {
  quadrant1xTyp14,
  quadrant2xTyp14,
  quadrant3xTyp14,
  quadrant4xTyp14,
} from "../diagramTypes/typ14/x";
import {
  quadrant1yTyp14,
  quadrant2yTyp14,
  quadrant3yTyp14,
  quadrant4yTyp14,
} from "../diagramTypes/typ14/y";
import {
  quadrant1zTyp14,
  quadrant2zTyp14,
  quadrant3zTyp14,
  quadrant4zTyp14,
} from "../diagramTypes/typ14/z";
import {
  quadrant1xTyp15,
  quadrant2xTyp15,
  quadrant3xTyp15,
  quadrant4xTyp15,
} from "../diagramTypes/typ15/x";
import {
  quadrant1yTyp15,
  quadrant2yTyp15,
  quadrant3yTyp15,
  quadrant4yTyp15,
} from "../diagramTypes/typ15/y";
import {
  quadrant1zTyp15,
  quadrant2zTyp15,
  quadrant3zTyp15,
  quadrant4zTyp15,
} from "../diagramTypes/typ15/z";
import {
  quadrant1xTyp16,
  quadrant2xTyp16,
  quadrant3xTyp16,
  quadrant4xTyp16,
} from "../diagramTypes/typ16/x";
import {
  quadrant1yTyp16,
  quadrant2yTyp16,
  quadrant3yTyp16,
  quadrant4yTyp16,
} from "../diagramTypes/typ16/y";
import {
  quadrant1zTyp16,
  quadrant2zTyp16,
  quadrant3zTyp16,
  quadrant4zTyp16,
} from "../diagramTypes/typ16/z";
import {
  quadrant1xTyp17,
  quadrant2xTyp17,
  quadrant3xTyp17,
  quadrant4xTyp17,
} from "../diagramTypes/typ17/x";
import {
  quadrant1yTyp17,
  quadrant2yTyp17,
  quadrant3yTyp17,
  quadrant4yTyp17,
} from "../diagramTypes/typ17/y";
import {
  quadrant1zTyp17,
  quadrant2zTyp17,
  quadrant3zTyp17,
  quadrant4zTyp17,
} from "../diagramTypes/typ17/z";
import {
  quadrant1xTyp18,
  quadrant2xTyp18,
  quadrant3xTyp18,
  quadrant4xTyp18,
} from "../diagramTypes/typ18/x";
import {
  quadrant1yTyp18,
  quadrant2yTyp18,
  quadrant3yTyp18,
  quadrant4yTyp18,
} from "../diagramTypes/typ18/y";
import {
  quadrant1zTyp18,
  quadrant2zTyp18,
  quadrant3zTyp18,
  quadrant4zTyp18,
} from "../diagramTypes/typ18/z";
import {
  quadrant1xTyp19,
  quadrant2xTyp19,
  quadrant3xTyp19,
  quadrant4xTyp19,
} from "../diagramTypes/typ19/x";
import {
  quadrant1yTyp19,
  quadrant2yTyp19,
  quadrant3yTyp19,
  quadrant4yTyp19,
} from "../diagramTypes/typ19/y";
import {
  quadrant1zTyp19,
  quadrant2zTyp19,
  quadrant3zTyp19,
  quadrant4zTyp19,
} from "../diagramTypes/typ19/z";

let isDone = false;
const formulaFunctions = [];

const initTyp1 = () => {
  formulaFunctions.quadrant1xTyp1 = quadrant1xTyp1;
  formulaFunctions.quadrant2xTyp1 = quadrant2xTyp1;
  formulaFunctions.quadrant3xTyp1 = quadrant3xTyp1;
  formulaFunctions.quadrant4xTyp1 = quadrant4xTyp1;

  formulaFunctions.quadrant1yTyp1 = quadrant1yTyp1;
  formulaFunctions.quadrant2yTyp1 = quadrant2yTyp1;
  formulaFunctions.quadrant3yTyp1 = quadrant3yTyp1;
  formulaFunctions.quadrant4yTyp1 = quadrant4yTyp1;

  formulaFunctions.quadrant1zTyp1 = quadrant1zTyp1;
  formulaFunctions.quadrant2zTyp1 = quadrant2zTyp1;
  formulaFunctions.quadrant3zTyp1 = quadrant3zTyp1;
  formulaFunctions.quadrant4zTyp1 = quadrant4zTyp1;
};

const initTyp2 = () => {
  formulaFunctions.quadrant1xTyp2 = quadrant1xTyp2;
  formulaFunctions.quadrant2xTyp2 = quadrant2xTyp2;
  formulaFunctions.quadrant3xTyp2 = quadrant3xTyp2;
  formulaFunctions.quadrant4xTyp2 = quadrant4xTyp2;

  formulaFunctions.quadrant1yTyp2 = quadrant1yTyp2;
  formulaFunctions.quadrant2yTyp2 = quadrant2yTyp2;
  formulaFunctions.quadrant3yTyp2 = quadrant3yTyp2;
  formulaFunctions.quadrant4yTyp2 = quadrant4yTyp2;

  formulaFunctions.quadrant1zTyp2 = quadrant1zTyp2;
  formulaFunctions.quadrant2zTyp2 = quadrant2zTyp2;
  formulaFunctions.quadrant3zTyp2 = quadrant3zTyp2;
  formulaFunctions.quadrant4zTyp2 = quadrant4zTyp2;
};

const initTyp3 = () => {
  formulaFunctions.quadrant1xTyp3 = quadrant1xTyp3;
  formulaFunctions.quadrant2xTyp3 = quadrant2xTyp3;
  formulaFunctions.quadrant3xTyp3 = quadrant3xTyp3;
  formulaFunctions.quadrant4xTyp3 = quadrant4xTyp3;

  formulaFunctions.quadrant1yTyp3 = quadrant1yTyp3;
  formulaFunctions.quadrant2yTyp3 = quadrant2yTyp3;
  formulaFunctions.quadrant3yTyp3 = quadrant3yTyp3;
  formulaFunctions.quadrant4yTyp3 = quadrant4yTyp3;

  formulaFunctions.quadrant1zTyp3 = quadrant1zTyp3;
  formulaFunctions.quadrant2zTyp3 = quadrant2zTyp3;
  formulaFunctions.quadrant3zTyp3 = quadrant3zTyp3;
  formulaFunctions.quadrant4zTyp3 = quadrant4zTyp3;
};

const initTyp4 = () => {
  formulaFunctions.quadrant1xTyp4 = quadrant1xTyp4;
  formulaFunctions.quadrant2xTyp4 = quadrant2xTyp4;
  formulaFunctions.quadrant3xTyp4 = quadrant3xTyp4;
  formulaFunctions.quadrant4xTyp4 = quadrant4xTyp4;

  formulaFunctions.quadrant1yTyp4 = quadrant1yTyp4;
  formulaFunctions.quadrant2yTyp4 = quadrant2yTyp4;
  formulaFunctions.quadrant3yTyp4 = quadrant3yTyp4;
  formulaFunctions.quadrant4yTyp4 = quadrant4yTyp4;

  formulaFunctions.quadrant1zTyp4 = quadrant1zTyp4;
  formulaFunctions.quadrant2zTyp4 = quadrant2zTyp4;
  formulaFunctions.quadrant3zTyp4 = quadrant3zTyp4;
  formulaFunctions.quadrant4zTyp4 = quadrant4zTyp4;
};

const initTyp5 = () => {
  formulaFunctions.quadrant1xTyp5 = quadrant1xTyp5;
  formulaFunctions.quadrant2xTyp5 = quadrant2xTyp5;
  formulaFunctions.quadrant3xTyp5 = quadrant3xTyp5;
  formulaFunctions.quadrant4xTyp5 = quadrant4xTyp5;

  formulaFunctions.quadrant1yTyp5 = quadrant1yTyp5;
  formulaFunctions.quadrant2yTyp5 = quadrant2yTyp5;
  formulaFunctions.quadrant3yTyp5 = quadrant3yTyp5;
  formulaFunctions.quadrant4yTyp5 = quadrant4yTyp5;

  formulaFunctions.quadrant1zTyp5 = quadrant1zTyp5;
  formulaFunctions.quadrant2zTyp5 = quadrant2zTyp5;
  formulaFunctions.quadrant3zTyp5 = quadrant3zTyp5;
  formulaFunctions.quadrant4zTyp5 = quadrant4zTyp5;
};

const initTyp6 = () => {
  formulaFunctions.quadrant1xTyp6 = quadrant1xTyp6;
  formulaFunctions.quadrant2xTyp6 = quadrant2xTyp6;
  formulaFunctions.quadrant3xTyp6 = quadrant3xTyp6;
  formulaFunctions.quadrant4xTyp6 = quadrant4xTyp6;

  formulaFunctions.quadrant1yTyp6 = quadrant1yTyp6;
  formulaFunctions.quadrant2yTyp6 = quadrant2yTyp6;
  formulaFunctions.quadrant3yTyp6 = quadrant3yTyp6;
  formulaFunctions.quadrant4yTyp6 = quadrant4yTyp6;

  formulaFunctions.quadrant1zTyp6 = quadrant1zTyp6;
  formulaFunctions.quadrant2zTyp6 = quadrant2zTyp6;
  formulaFunctions.quadrant3zTyp6 = quadrant3zTyp6;
  formulaFunctions.quadrant4zTyp6 = quadrant4zTyp6;
};

const initTyp7 = () => {
  formulaFunctions.quadrant1xTyp7 = quadrant1xTyp7;
  formulaFunctions.quadrant2xTyp7 = quadrant2xTyp7;
  formulaFunctions.quadrant3xTyp7 = quadrant3xTyp7;
  formulaFunctions.quadrant4xTyp7 = quadrant4xTyp7;

  formulaFunctions.quadrant1yTyp7 = quadrant1yTyp7;
  formulaFunctions.quadrant2yTyp7 = quadrant2yTyp7;
  formulaFunctions.quadrant3yTyp7 = quadrant3yTyp7;
  formulaFunctions.quadrant4yTyp7 = quadrant4yTyp7;

  formulaFunctions.quadrant1zTyp7 = quadrant1zTyp7;
  formulaFunctions.quadrant2zTyp7 = quadrant2zTyp7;
  formulaFunctions.quadrant3zTyp7 = quadrant3zTyp7;
  formulaFunctions.quadrant4zTyp7 = quadrant4zTyp7;
};

const initTyp8 = () => {
  formulaFunctions.quadrant1xTyp8 = quadrant1xTyp8;
  formulaFunctions.quadrant2xTyp8 = quadrant2xTyp8;
  formulaFunctions.quadrant3xTyp8 = quadrant3xTyp8;
  formulaFunctions.quadrant4xTyp8 = quadrant4xTyp8;

  formulaFunctions.quadrant1yTyp8 = quadrant1yTyp8;
  formulaFunctions.quadrant2yTyp8 = quadrant2yTyp8;
  formulaFunctions.quadrant3yTyp8 = quadrant3yTyp8;
  formulaFunctions.quadrant4yTyp8 = quadrant4yTyp8;

  formulaFunctions.quadrant1zTyp8 = quadrant1zTyp8;
  formulaFunctions.quadrant2zTyp8 = quadrant2zTyp8;
  formulaFunctions.quadrant3zTyp8 = quadrant3zTyp8;
  formulaFunctions.quadrant4zTyp8 = quadrant4zTyp8;
};

const initTyp9 = () => {
  formulaFunctions.quadrant1xTyp9 = quadrant1xTyp9;
  formulaFunctions.quadrant2xTyp9 = quadrant2xTyp9;
  formulaFunctions.quadrant3xTyp9 = quadrant3xTyp9;
  formulaFunctions.quadrant4xTyp9 = quadrant4xTyp9;

  formulaFunctions.quadrant1yTyp9 = quadrant1yTyp9;
  formulaFunctions.quadrant2yTyp9 = quadrant2yTyp9;
  formulaFunctions.quadrant3yTyp9 = quadrant3yTyp9;
  formulaFunctions.quadrant4yTyp9 = quadrant4yTyp9;

  formulaFunctions.quadrant1zTyp9 = quadrant1zTyp9;
  formulaFunctions.quadrant2zTyp9 = quadrant2zTyp9;
  formulaFunctions.quadrant3zTyp9 = quadrant3zTyp9;
  formulaFunctions.quadrant4zTyp9 = quadrant4zTyp9;
};

const initTyp10 = () => {
  formulaFunctions.quadrant1xTyp10 = quadrant1xTyp10;
  formulaFunctions.quadrant2xTyp10 = quadrant2xTyp10;
  formulaFunctions.quadrant3xTyp10 = quadrant3xTyp10;
  formulaFunctions.quadrant4xTyp10 = quadrant4xTyp10;

  formulaFunctions.quadrant1yTyp10 = quadrant1yTyp10;
  formulaFunctions.quadrant2yTyp10 = quadrant2yTyp10;
  formulaFunctions.quadrant3yTyp10 = quadrant3yTyp10;
  formulaFunctions.quadrant4yTyp10 = quadrant4yTyp10;

  formulaFunctions.quadrant1zTyp10 = quadrant1zTyp10;
  formulaFunctions.quadrant2zTyp10 = quadrant2zTyp10;
  formulaFunctions.quadrant3zTyp10 = quadrant3zTyp10;
  formulaFunctions.quadrant4zTyp10 = quadrant4zTyp10;
};

const initTyp11 = () => {
  formulaFunctions.quadrant1xTyp11 = quadrant1xTyp11;
  formulaFunctions.quadrant2xTyp11 = quadrant2xTyp11;
  formulaFunctions.quadrant3xTyp11 = quadrant3xTyp11;
  formulaFunctions.quadrant4xTyp11 = quadrant4xTyp11;

  formulaFunctions.quadrant1yTyp11 = quadrant1yTyp11;
  formulaFunctions.quadrant2yTyp11 = quadrant2yTyp11;
  formulaFunctions.quadrant3yTyp11 = quadrant3yTyp11;
  formulaFunctions.quadrant4yTyp11 = quadrant4yTyp11;

  formulaFunctions.quadrant1zTyp11 = quadrant1zTyp11;
  formulaFunctions.quadrant2zTyp11 = quadrant2zTyp11;
  formulaFunctions.quadrant3zTyp11 = quadrant3zTyp11;
  formulaFunctions.quadrant4zTyp11 = quadrant4zTyp11;
};

const initTyp12 = () => {
  formulaFunctions.quadrant1xTyp12 = quadrant1xTyp12;
  formulaFunctions.quadrant2xTyp12 = quadrant2xTyp12;
  formulaFunctions.quadrant3xTyp12 = quadrant3xTyp12;
  formulaFunctions.quadrant4xTyp12 = quadrant4xTyp12;

  formulaFunctions.quadrant1yTyp12 = quadrant1yTyp12;
  formulaFunctions.quadrant2yTyp12 = quadrant2yTyp12;
  formulaFunctions.quadrant3yTyp12 = quadrant3yTyp12;
  formulaFunctions.quadrant4yTyp12 = quadrant4yTyp12;

  formulaFunctions.quadrant1zTyp12 = quadrant1zTyp12;
  formulaFunctions.quadrant2zTyp12 = quadrant2zTyp12;
  formulaFunctions.quadrant3zTyp12 = quadrant3zTyp12;
  formulaFunctions.quadrant4zTyp12 = quadrant4zTyp12;
};

const initTyp13 = () => {
  formulaFunctions.quadrant1xTyp13 = quadrant1xTyp13;
  formulaFunctions.quadrant2xTyp13 = quadrant2xTyp13;
  formulaFunctions.quadrant3xTyp13 = quadrant3xTyp13;
  formulaFunctions.quadrant4xTyp13 = quadrant4xTyp13;

  formulaFunctions.quadrant1yTyp13 = quadrant1yTyp13;
  formulaFunctions.quadrant2yTyp13 = quadrant2yTyp13;
  formulaFunctions.quadrant3yTyp13 = quadrant3yTyp13;
  formulaFunctions.quadrant4yTyp13 = quadrant4yTyp13;

  formulaFunctions.quadrant1zTyp13 = quadrant1zTyp13;
  formulaFunctions.quadrant2zTyp13 = quadrant2zTyp13;
  formulaFunctions.quadrant3zTyp13 = quadrant3zTyp13;
  formulaFunctions.quadrant4zTyp13 = quadrant4zTyp13;
};

const initTyp14 = () => {
  formulaFunctions.quadrant1xTyp14 = quadrant1xTyp14;
  formulaFunctions.quadrant2xTyp14 = quadrant2xTyp14;
  formulaFunctions.quadrant3xTyp14 = quadrant3xTyp14;
  formulaFunctions.quadrant4xTyp14 = quadrant4xTyp14;

  formulaFunctions.quadrant1yTyp14 = quadrant1yTyp14;
  formulaFunctions.quadrant2yTyp14 = quadrant2yTyp14;
  formulaFunctions.quadrant3yTyp14 = quadrant3yTyp14;
  formulaFunctions.quadrant4yTyp14 = quadrant4yTyp14;

  formulaFunctions.quadrant1zTyp14 = quadrant1zTyp14;
  formulaFunctions.quadrant2zTyp14 = quadrant2zTyp14;
  formulaFunctions.quadrant3zTyp14 = quadrant3zTyp14;
  formulaFunctions.quadrant4zTyp14 = quadrant4zTyp14;
};

const initTyp15 = () => {
  formulaFunctions.quadrant1xTyp15 = quadrant1xTyp15;
  formulaFunctions.quadrant2xTyp15 = quadrant2xTyp15;
  formulaFunctions.quadrant3xTyp15 = quadrant3xTyp15;
  formulaFunctions.quadrant4xTyp15 = quadrant4xTyp15;

  formulaFunctions.quadrant1yTyp15 = quadrant1yTyp15;
  formulaFunctions.quadrant2yTyp15 = quadrant2yTyp15;
  formulaFunctions.quadrant3yTyp15 = quadrant3yTyp15;
  formulaFunctions.quadrant4yTyp15 = quadrant4yTyp15;

  formulaFunctions.quadrant1zTyp15 = quadrant1zTyp15;
  formulaFunctions.quadrant2zTyp15 = quadrant2zTyp15;
  formulaFunctions.quadrant3zTyp15 = quadrant3zTyp15;
  formulaFunctions.quadrant4zTyp15 = quadrant4zTyp15;
};

const initTyp16 = () => {
  formulaFunctions.quadrant1xTyp16 = quadrant1xTyp16;
  formulaFunctions.quadrant2xTyp16 = quadrant2xTyp16;
  formulaFunctions.quadrant3xTyp16 = quadrant3xTyp16;
  formulaFunctions.quadrant4xTyp16 = quadrant4xTyp16;

  formulaFunctions.quadrant1yTyp16 = quadrant1yTyp16;
  formulaFunctions.quadrant2yTyp16 = quadrant2yTyp16;
  formulaFunctions.quadrant3yTyp16 = quadrant3yTyp16;
  formulaFunctions.quadrant4yTyp16 = quadrant4yTyp16;

  formulaFunctions.quadrant1zTyp16 = quadrant1zTyp16;
  formulaFunctions.quadrant2zTyp16 = quadrant2zTyp16;
  formulaFunctions.quadrant3zTyp16 = quadrant3zTyp16;
  formulaFunctions.quadrant4zTyp16 = quadrant4zTyp16;
};

const initTyp17 = () => {
  formulaFunctions.quadrant1xTyp17 = quadrant1xTyp17;
  formulaFunctions.quadrant2xTyp17 = quadrant2xTyp17;
  formulaFunctions.quadrant3xTyp17 = quadrant3xTyp17;
  formulaFunctions.quadrant4xTyp17 = quadrant4xTyp17;

  formulaFunctions.quadrant1yTyp17 = quadrant1yTyp17;
  formulaFunctions.quadrant2yTyp17 = quadrant2yTyp17;
  formulaFunctions.quadrant3yTyp17 = quadrant3yTyp17;
  formulaFunctions.quadrant4yTyp17 = quadrant4yTyp17;

  formulaFunctions.quadrant1zTyp17 = quadrant1zTyp17;
  formulaFunctions.quadrant2zTyp17 = quadrant2zTyp17;
  formulaFunctions.quadrant3zTyp17 = quadrant3zTyp17;
  formulaFunctions.quadrant4zTyp17 = quadrant4zTyp17;
};

const initTyp18 = () => {
  formulaFunctions.quadrant1xTyp18 = quadrant1xTyp18;
  formulaFunctions.quadrant2xTyp18 = quadrant2xTyp18;
  formulaFunctions.quadrant3xTyp18 = quadrant3xTyp18;
  formulaFunctions.quadrant4xTyp18 = quadrant4xTyp18;

  formulaFunctions.quadrant1yTyp18 = quadrant1yTyp18;
  formulaFunctions.quadrant2yTyp18 = quadrant2yTyp18;
  formulaFunctions.quadrant3yTyp18 = quadrant3yTyp18;
  formulaFunctions.quadrant4yTyp18 = quadrant4yTyp18;

  formulaFunctions.quadrant1zTyp18 = quadrant1zTyp18;
  formulaFunctions.quadrant2zTyp18 = quadrant2zTyp18;
  formulaFunctions.quadrant3zTyp18 = quadrant3zTyp18;
  formulaFunctions.quadrant4zTyp18 = quadrant4zTyp18;
};

const initTyp19 = () => {
  formulaFunctions.quadrant1xTyp19 = quadrant1xTyp19;
  formulaFunctions.quadrant2xTyp19 = quadrant2xTyp19;
  formulaFunctions.quadrant3xTyp19 = quadrant3xTyp19;
  formulaFunctions.quadrant4xTyp19 = quadrant4xTyp19;

  formulaFunctions.quadrant1yTyp19 = quadrant1yTyp19;
  formulaFunctions.quadrant2yTyp19 = quadrant2yTyp19;
  formulaFunctions.quadrant3yTyp19 = quadrant3yTyp19;
  formulaFunctions.quadrant4yTyp19 = quadrant4yTyp19;

  formulaFunctions.quadrant1zTyp19 = quadrant1zTyp19;
  formulaFunctions.quadrant2zTyp19 = quadrant2zTyp19;
  formulaFunctions.quadrant3zTyp19 = quadrant3zTyp19;
  formulaFunctions.quadrant4zTyp19 = quadrant4zTyp19;
};

const initFormulaData = () => {
  initTyp1();
  initTyp2();
  initTyp3();
  initTyp4();
  initTyp5();
  initTyp6();
  initTyp7();
  initTyp8();
  initTyp9();
  initTyp10();
  initTyp11();
  initTyp12();
  initTyp13();
  initTyp14();
  initTyp15();
  initTyp16();
  initTyp17();
  initTyp18();
  initTyp19();
  isDone = true;
};

export default (diagramType, qNumber, axis) => {
  if (!isDone) initFormulaData();

  let type = diagramType;

  switch (diagramType) {
    case "1_1":
    case "1_2":
    case "1_3":
    case "1_4":
    case "1_5":
      type = "1";
      break;
    default:
      break;
  }

  const functionName = `quadrant${qNumber}${axis}Typ${type}`;
  return formulaFunctions[functionName];
};
