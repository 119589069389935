import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core/";

import {
  updateYAxisLabelText,
  updateXAxisLabelText,
} from "../../subjects/d3Subjects";

const useStyles = makeStyles({
  root: {
    margin: 5,
  },
});

export default function AxisLabel(props) {
  const classes = useStyles();

  const { label, qNumber, axis, position, userProject, tabIndex } = props;
  const { quadrants, isCongruent } = userProject;

  const getAxisLabelText = (labelPosition) => {
    const quadrantIndex = tabIndex;
    const isBottomQuadrant = qNumber === 3 || qNumber === 4;
    const isLeftSideQuadrant = qNumber === 2 || qNumber === 3;

    let initialText;
    let labelID = "headerText";
    let targetQuadrantID = quadrantIndex;

    switch (true) {
      // Y
      case axis === "y":
        initialText = quadrants[quadrantIndex]?.y.yAxisLabel;
        labelID = "yAxisLabel";
        break;

      // Top Quadrants
      case axis === "x" &&
        isBottomQuadrant === false &&
        labelPosition === "top":
        initialText = quadrants[quadrantIndex]?.legend.headerText;
        break;
      case axis === "x" &&
        isBottomQuadrant === false &&
        labelPosition === "bottom":
        targetQuadrantID = isLeftSideQuadrant ? 1 : 0;
        initialText = quadrants[targetQuadrantID].x.xAxisLabel;
        labelID = "xAxisLabel";
        break;

      // Bottom Quadrants
      case axis === "x" &&
        isBottomQuadrant === true &&
        labelPosition === "top" &&
        qNumber === 3:
        targetQuadrantID = isLeftSideQuadrant ? 1 : 0;
        initialText = quadrants[targetQuadrantID].x.xAxisLabel;
        labelID = "xAxisLabel";
        break;
      case axis === "x" &&
        isBottomQuadrant === true &&
        labelPosition === "bottom":
        initialText = quadrants[quadrantIndex]?.legend.headerText;
        break;
      // special case!
      case axis === "x" &&
        isBottomQuadrant === true &&
        labelPosition === "top" &&
        qNumber === 4:
        targetQuadrantID = isCongruent ? 0 : quadrantIndex;
        labelID = isCongruent ? "xAxisLabel" : "headerText";
        initialText = isCongruent
          ? quadrants[quadrantIndex]?.legend.headerText
          : quadrants[quadrantIndex].x.xAxisLabel;
        break;

      default:
        initialText = "";
        break;
    }
    return {
      axis,
      quadrantIndex,
      targetQuadrantID,
      labelID,
      labelPosition,
      isBottomQuadrant,
      isLeftSideQuadrant,
      initialText,
    };
  };

  const labelData = getAxisLabelText(position);
  const [textValue, setTextValue] = useState(labelData.initialText);

  const handleChange = (newText) => {
    setTextValue(newText);

    if (axis === "x") {
      updateXAxisLabelText({ newText, ...labelData });
    }

    if (axis === "y") {
      updateYAxisLabelText({ newText, ...labelData });
    }
  };

  if (textValue) {
    return (
      <TextField
        className={classes.root}
        multiline
        label={label || "label"}
        value={textValue || ""}
        onChange={(event) => handleChange(event.target.value)}
      />
    );
  }
  return null;
}
