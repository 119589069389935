import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp12(x, y) {
  return safeDivByZero(y, x) * 100;
}

export function quadrant2zTyp12(x, y) {
  return safeDivByZero(x, y) * 100;
}

export function quadrant3zTyp12(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp12(x, y) {
  return safeDivByZero(x, y);
}
