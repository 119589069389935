import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp10(y, z) {
  return y * z;
}

export function quadrant2xTyp10(y, z) {
  return y * z;
}

export function quadrant3xTyp10(y, z) {
  return safeDivByZero(z, y);
}

export function quadrant4xTyp10(y, z) {
  return y * z;
}
