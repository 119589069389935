import * as d3 from "d3";

import React, { useEffect, useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  countDecimalsPlaces,
  formatNumber,
  lineFunction,
  rangeFunction,
  readObjectFromLocalStorage,
  readWorkingLinesFromLocalStorage,
  storeWorkingLinesInLocalStorage,
} from "../../helpers/util";
import { useData } from "../DataProvider";
import WorkingLine from "./chartFunctions/WorkingLine";
import {
  getDeleteAllWorkingLinesListener,
  getExtendWorkingLineHorizontalSubject,
  getExtendWorkingLineVerticalSubject,
  getResetZoomAllListener,
  getResetZoomListener,
  getUpdateZoomListener,
  resetZoomTrigger,
  resetZoomTriggerAll,
  getUpdateYAxesTextListener,
  getUpdateXAxesTextListener,
} from "../subjects/d3Subjects";
import Quadrant from "./chartFunctions/Quadrant";
import "numeral/locales/de";
import "numeral/locales/en-gb";

/* +++ Important note:
 * These global variables points to the current quadrant when component is loaded but gets overridden when the
 * next quadrant is loaded. So functions which should be executed later like event handler functions
 * cannot use global variables because they have already been overwritten. So to get the right values,
 * they need to be passed to the function by arguments.
 */

let showAnimation = true;

function Chart({
  id,
  formulaX,
  formulaY,
  formulaZ,
  isOnLeftSide = false,
  isOnBottomSide = false,
  index,
  areXAxisCongruent,
  onLabelClick,
}) {
  const svgRef = useRef();
  let svg;
  const {
    userProjects,
    projectId,
    quadrants,
    addQuadrant,
    location,
    svgs,
    setSvgs,
    setUserProjects,
  } = useData();
  let quadrant;

  const svgMargin = {
    top: 55,
    right: 55,
    bottom: 60,
    left: 80,
  };

  const viewBoxWidth =
    window.innerWidth / 2 - 15 > 750 ? 750 : window.innerWidth / 2 - 15; //window.innerWidth / 2 - 15;
  let viewBoxHeight = (window.innerHeight / 2) * 0.83;

  let currentProject = userProjects[projectId];
  if (localStorage.getItem("userProjects") !== null) {
    const userProjectsLocalStorage = readObjectFromLocalStorage("userProjects");
    currentProject = userProjectsLocalStorage[projectId];
  }

  const xMin = Number(currentProject.quadrants[index].x.min);
  const xMainInterval = Number(currentProject.quadrants[index].x.mainIntervall);
  const xNumberIntervals = Number(
    currentProject.quadrants[index].x.numberIntervalls
  );
  const xNumberOfHelperIntervals = Number(
    currentProject.quadrants[index].x.helperIntervall
  );
  const xNumberOfDecimalPoints = Number(
    currentProject.quadrants[index].x.numberDecPoints
  );

  const yMin = Number(currentProject.quadrants[index].y.min);
  const yMainInterval = Number(currentProject.quadrants[index].y.mainIntervall);
  const yNumberIntervals = Number(
    currentProject.quadrants[index].y.numberIntervalls
  );
  const yNumberOfHelperIntervals = Number(
    currentProject.quadrants[index].y.helperIntervall
  );
  const yNumberOfDecimalPoints = Number(
    currentProject.quadrants[index].y.numberDecPoints
  );

  const amountDataSeries = Number(
    currentProject.quadrants[index].dataseries.number
  );
  const minDataSeries = Number(
    currentProject.quadrants[index].dataseries.minDS
  );
  const stepsDataSeries = Number(
    currentProject.quadrants[index].dataseries.steps
  );
  const fractionalDigits = 6;

  const xMax = xMin + xMainInterval * xNumberIntervals;
  const yMax = yMin + yMainInterval * yNumberIntervals;

  Chart.propTypes = {
    id: PropTypes.string.isRequired,
    formulaX: PropTypes.func.isRequired,
    formulaY: PropTypes.func.isRequired,
    formulaZ: PropTypes.func.isRequired,
    isOnLeftSide: PropTypes.bool,
    isOnBottomSide: PropTypes.bool,
    index: PropTypes.number.isRequired,
    onLabelClick: PropTypes.func.isRequired,
  };

  Chart.defaultProps = {
    isOnLeftSide: false,
    isOnBottomSide: false,
  };

  document.addEventListener("keyup", (e) => {
    if (e.code === "F11") {
      if (document.fullscreenElement) viewBoxHeight = window.innerHeight;
      else viewBoxHeight = (window.innerHeight / 2) * 0.72;
      window.location.reload();
    }
  });

  function addLegend(
    lines,
    strokeWidth,
    strokeLine,
    colorName,
    height,
    numberOfDigits,
    numberOfLines,
    decimalPlaces
  ) {
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", "translate(10,-30)");

    let marginLeft = quadrant.width / 2 - numberOfDigits * 5 * numberOfLines;
    if (marginLeft <= 0) marginLeft = 0;
    let scaleFactor = 9 * numberOfDigits;
    scaleFactor = scaleFactor < 20 ? 20 : scaleFactor;
    let calcHeight;
    if (quadrant.isBottom) {
      calcHeight = height + 55;
    } else calcHeight = 20;

    let littleMargin = 0;
    if (numberOfDigits === 1) {
      littleMargin = 3;
    } else if (numberOfDigits === 2) {
      littleMargin = 0;
    }

    legend
      .selectAll("text")
      .data(lines)
      .enter()
      .append("text")
      .attr("class", "selectDisable")
      .attr("x", (d, idx) => marginLeft + idx * scaleFactor + littleMargin)
      .attr("y", calcHeight - 5)
      .style("stroke", "#0064A7")
      .style("font-size", "12")
      .text((d) => formatNumber(d.z, decimalPlaces));

    legend
      .selectAll("rect")
      .data(lines)
      .enter()
      .append("rect")
      .attr("class", "selectDisable")
      .attr("x", (d, idx) => marginLeft + idx * scaleFactor)
      .attr("y", calcHeight)
      .attr("width", scaleFactor - 4)
      .attr("height", 1)
      .style("fill", "red")
      .style("stroke", (d, colorIndex) => colorName[Math.floor(colorIndex / 3)])
      .style("stroke-width", "strokeWidth")
      .style(
        "stroke-dasharray",
        (d, strokeIndex) => strokeLine[strokeIndex % 3]
      );
  }

  //
  function setLineCalculationData(newX, newY) {
    const numberOfLines = Number(amountDataSeries, 10);
    const stepRate = Number(stepsDataSeries, 10);

    quadrant.formulars.minDS = Number(minDataSeries, 10);
    quadrant.formulars.stepRate = stepRate;
    quadrant.formulars.formulaY = formulaY;
    quadrant.formulars.numberOfLines = numberOfLines;
    quadrant.formulars.newX = newX;
    quadrant.formulars.newY = newY;
  }

  //
  function calculateLineValues() {
    const numberOfLines = Number(amountDataSeries, 10);
    const minDS = Number(minDataSeries, 10);
    const stepRate = Number(stepsDataSeries, 10);
    const lines = [];
    const numberOfDigitsArrayLegend = [];
    let numberOfDigitsLegend = 0;
    let zValue;

    for (let i = 0; i < numberOfLines; i += 1) {
      zValue = minDS + stepRate * i;
      const fixedNumber = +zValue.toFixed(fractionalDigits);

      setLineCalculationData(quadrant.x, quadrant.y);

      lines.push({ z: fixedNumber });
      numberOfDigitsArrayLegend.push(
        `${formatNumber(fixedNumber, countDecimalsPlaces(fixedNumber))}`.length
      );
    }

    const decimalPlaces = lines.reduce(
      (acc, value) => Math.max(acc, countDecimalsPlaces(value.z)),
      0
    );

    numberOfDigitsArrayLegend.push(2 + decimalPlaces);

    numberOfDigitsLegend = Math.max(...numberOfDigitsArrayLegend);

    const lineData = rangeFunction(xMin, xMax, quadrant.xNumberOfDecimalPoints);

    return {
      lines,
      numberOfDigitsLegend,
      lineData,
      numberOfLines,
      decimalPlaces,
    };
  }

  function drawLines() {
    const {
      lines,
      numberOfDigitsLegend,
      lineData,
      numberOfLines,
      decimalPlaces,
    } = calculateLineValues();

    quadrant.lines = lines;
    const strokeWidth = 2;
    const strokeLine = ["1 0", "6 1", "1 2"];
    const colorName = [
      "#0064A7",
      "#A63708",
      "#B18C02",
      "#78AACC",
      "#F37B47",
      "#DEAF00",
    ];

    lines.forEach((line, lineIndex) => {
      const lineId = `quadrantLines_${index}_${lineIndex}`;

      const lineFunc = lineFunction(
        Number(line.z),
        quadrant.x,
        quadrant.y,
        formulaY
      );
      quadrant.plot
        .append("path")
        .data([lineData])
        .attr("id", lineId)
        .attr("d", lineFunc)
        .attr("class", "lines")
        .style("stroke", colorName[Math.floor(lineIndex / 3)])
        .style("stroke-width", strokeWidth)
        .style("stroke-dasharray", strokeLine[lineIndex % 3])
        .style("fill", "none")
        .style("stroke-linejoin", "round");
    });

    const copyLinesArray = [...lines];
    if (numberOfDigitsLegend < 5) {
      addLegend(
        copyLinesArray,
        strokeWidth,
        strokeLine,
        colorName,
        quadrant.height,
        numberOfDigitsLegend,
        numberOfLines,
        decimalPlaces
      );
    }
    if (numberOfDigitsLegend === 5) {
      const lowerHalfLines = copyLinesArray.splice(0, 17);
      addLegend(
        lowerHalfLines,
        strokeWidth,
        strokeLine,
        colorName,
        quadrant.height,
        numberOfDigitsLegend,
        numberOfLines,
        decimalPlaces
      );
    } else if (numberOfDigitsLegend === 6) {
      const lowerHalfLines = copyLinesArray.splice(0, 14);
      addLegend(
        lowerHalfLines,
        strokeWidth,
        strokeLine,
        colorName,
        quadrant.height,
        numberOfDigitsLegend,
        numberOfLines,
        decimalPlaces
      );
    } else if (numberOfDigitsLegend === 7) {
      const lowerHalfLines = copyLinesArray.splice(0, 12);
      addLegend(
        lowerHalfLines,
        strokeWidth,
        strokeLine,
        colorName,
        quadrant.height,
        numberOfDigitsLegend,
        numberOfLines,
        decimalPlaces
      );
    } else {
      const lowerHalfLines = copyLinesArray.splice(0, 10);
      addLegend(
        lowerHalfLines,
        strokeWidth,
        strokeLine,
        colorName,
        quadrant.height,
        numberOfDigitsLegend,
        numberOfLines,
        decimalPlaces
      );
    }
  }

  function makeXGridLines() {
    return d3.axisBottom(quadrant.x).ticks(10);
  }

  function makeYGridLines() {
    return d3.axisLeft(quadrant.y).ticks(10);
  }

  // initial Y-Label only for Q1 & Q4
  function addYLabel() {
    if (quadrant.isFirstQuadrant || quadrant.isFourthQuadrant) {
      const firstRowlabelPosX = -60;
      const secondRowlabelPosX = -45;
      const labelPosY = quadrant.height / 2;
      const fontSize = "18px";

      const labelID = quadrant.isBottom
        ? "bottomQuadrantYLable"
        : "topQuadrantYLable";

      const text =
        userProjects[projectId].quadrants[quadrant.index].y.yAxisLabel;

      // in case of long label split text into two separate texts
      const words = text.split(" ");

      const maximumCharactersBeforeSplit = 40;
      const isLongText =
        text.length > maximumCharactersBeforeSplit ? true : false;

      // define split point by character length
      const wordsMiddleIndex = isLongText
        ? Math.floor(words.length / 2)
        : words.length;

      const firstRowText = [];
      const secondRowText = [];

      words.forEach((word, i) => {
        if (i < wordsMiddleIndex) {
          firstRowText.push(word);
        } else {
          secondRowText.push(word);
        }
      });

      // first row
      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + firstRowlabelPosX + ", " + labelPosY + ")"
        )
        .append("text")
        .attr("id", labelID)
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        .style("font-size", fontSize)
        .style("font-weight", "bold")
        .style("fill", "steelblue")
        .style("cursor", "pointer")
        .style("fill-opacity", "1")
        .text(firstRowText.join(" "))
        .on("click", () => onLabelClick(labelID, quadrant));

      // second row
      svg
        .append("g")
        .attr(
          "transform",
          "translate(" + secondRowlabelPosX + ", " + labelPosY + ")"
        )
        .append("text")
        .attr("id", labelID)
        .attr("text-anchor", "middle")
        .attr("transform", "rotate(-90)")
        .style("font-size", fontSize)
        .style("font-weight", "bold")
        .style("cursor", "pointer")
        .style("fill", "steelblue")
        .style("fill-opacity", "1")
        .text(secondRowText.join(" "))
        .on("click", () => onLabelClick(labelID, quadrant));
    }
  }

  function addXLabel(position) {
    const labelPosX = quadrant.width / 2;
    const labelPosY = position === "top" ? -40 : quadrant.height + 50;
    const q = userProjects[projectId].quadrants;

    let text = "";
    let labelID = "";

    switch (true) {
      //-----------------------------------------------------
      // Right Quadrants
      //-----------------------------------------------------
      // Q1
      case quadrant.isFirstQuadrant:
        switch (true) {
          case position === "top":
            labelID = "firstQuadrantTopLabel";
            text = q[quadrant.index].legend.headerText;
            break;
          case position === "bottom":
            labelID = "firstQuadrantBottomLabel";
            text = areXAxisCongruent ? "" : q[quadrant.index].x.xAxisLabel;
            break;
          default:
            break;
        }
        break;
      // Q4
      case quadrant.isFourthQuadrant:
        switch (true) {
          case position === "top":
            labelID = "fourthQuadrantTopLabel";
            text = areXAxisCongruent ? "" : q[quadrant.index].x.xAxisLabel;
            break;
          case position === "bottom":
            labelID = "fourthQuadrantBottomLabel";
            text = q[quadrant.index].legend.headerText;
            break;
          default:
            break;
        }
        break;
      //-----------------------------------------------------
      // Left Quadrants
      //-----------------------------------------------------
      // Q2
      case quadrant.isSecondQuadrant:
        switch (true) {
          case position === "top":
            labelID = "secondQuadrantTopLabel";
            text = q[quadrant.index].legend.headerText;
            break;
          case position === "bottom":
            labelID = "secondQuadrantBottomLabel";
            text = areXAxisCongruent ? "" : q[quadrant.index].x.xAxisLabel;
            break;
          default:
            break;
        }
        break;
      // Q3
      case quadrant.isThirdQuadrant:
        switch (true) {
          case position === "top":
            labelID = "thirdQuadrantTopLabel";
            text = areXAxisCongruent ? "" : q[quadrant.index].x.xAxisLabel;
            break;
          case position === "bottom":
            labelID = "thirdQuadrantBottomLabel";
            text = q[quadrant.index].legend.headerText;
            break;
          default:
            break;
        }
        break;
      //
      default:
        break;
    }

    svg
      .append("g")
      .attr("transform", "translate(" + labelPosX + ", " + labelPosY + ")")
      .append("text")
      .attr("id", labelID)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .style("font-weight", "bold")
      .style("fill", "steelblue")
      .style("fill-opacity", "1")
      .style("cursor", "pointer")
      .text(text)
      .on("click", () => onLabelClick(labelID, quadrant));
  }

  function updateAxisLabel(labelID, newText) {
    d3.select(`#${labelID}`).text(newText);
  }

  function addGrid() {
    quadrant.xGrid = svg
      .append("g")
      .attr("class", "grid")
      .attr("class", "axis")
      .attr("id", "grid")
      .attr("transform", `translate(0,${0})`)
      .call(makeXGridLines().tickSize(quadrant.height).tickFormat(""));

    quadrant.yGrid = svg
      .append("g")
      .attr("class", "grid")
      .attr("class", "axis")
      .attr("id", "grid")
      .attr("transform", `translate(0,${0})`)
      .call(makeYGridLines().tickSize(-quadrant.width).tickFormat(""));
  }

  function removePreviousRender() {
    const host = d3.select(svgRef.current);
    host.select("g").remove();
  }

  const addWorkingLine = (
    posX,
    posY,
    valueX,
    valueY,
    noOffset = false,
    name,
    color,
    strokeWidth,
    strokeIndex,
    isCombined,
    arrowDirectionIndex,
    sequenceNumber,
    decimalPlaces,
    isVisible,
    showLabels
  ) => {
    const workingLine = new WorkingLine(
      quadrant,
      posX,
      posY,
      valueX,
      valueY,
      noOffset,
      name,
      color,
      strokeWidth,
      strokeIndex,
      isCombined,
      arrowDirectionIndex,
      sequenceNumber,
      decimalPlaces,
      isVisible,
      showLabels
    );
    quadrant.workingLines.push(workingLine);
    storeWorkingLinesInLocalStorage(quadrant);
  };

  function getPositionsFromVerticalWorkingLine(q) {
    const sequenceNumber = WorkingLine.nextSequenceNumber(q);
    const neighboringWorkingLine = q.verticalNeighbor.workingLines.find(
      (line) => line.sequenceNumber === sequenceNumber
    );

    if (neighboringWorkingLine)
      return {
        posXVertical: neighboringWorkingLine.posX,
        posYVertical: neighboringWorkingLine.posY,
      };

    return { posXVertical: null, posYVertical: null };
  }

  function getPositionsFromWorkingLines(q) {
    const sequenceNumber = WorkingLine.nextSequenceNumber(q);
    const neighboringWorkingLine = q.horizontalNeighbor.workingLines.find(
      (line) => line.sequenceNumber === sequenceNumber
    );

    if (neighboringWorkingLine) {
      return {
        posXHorizontal: neighboringWorkingLine.posX,
        posYHorizontal: neighboringWorkingLine.posY,
      };
    }
    return { posXHorizontal: null, posYHorizontal: null };
  }

  function updateZoomLevel(instance, zoomLevel) {
    instance.svg.call(
      instance.zoom.transform,
      d3.zoomIdentity.scale(zoomLevel)
    );
  }

  function extendWorkingLineHorizontalListener(q) {
    return getExtendWorkingLineHorizontalSubject().subscribe((qNumber) => {
      // resetting zoom to get the right pixel (offset) position
      resetZoomTriggerAll();
      if (qNumber === index + 1) {
        const { posXHorizontal, posYHorizontal } =
          getPositionsFromWorkingLines(q);
        const { posXVertical } = getPositionsFromVerticalWorkingLine(q);
        if (posYHorizontal !== null) {
          const attributes = extractAttributes(q);

          addWorkingLine(
            posXVertical ?? posXHorizontal,
            posYHorizontal || 100,
            undefined,
            undefined,
            false,
            attributes.name,
            attributes.color,
            attributes.strokeWidth,
            attributes.strokeIndex
          );
        }
      }
    });
  }

  function extendWorkingLineVerticalListener(q) {
    return getExtendWorkingLineVerticalSubject().subscribe((qNumber) => {
      // resetting zoom to get the right pixel (offset) position
      resetZoomTriggerAll();
      if (qNumber === index + 1) {
        const { posXVertical, posYVertical } =
          getPositionsFromVerticalWorkingLine(q);
        const { posYHorizontal } = getPositionsFromWorkingLines(q);
        if (posXVertical !== null) {
          const attributes = extractAttributes(q);

          addWorkingLine(
            posXVertical || 100,
            posYHorizontal ?? posYVertical,
            undefined,
            undefined,
            false,
            attributes.name,
            attributes.color,
            attributes.strokeWidth,
            attributes.strokeIndex
          );
        }
      }
    });
  }

  function addUpdateYAxesListener() {
    return getUpdateYAxesTextListener().subscribe((data) => {
      const labelID = data.isBottomQuadrant
        ? "bottomQuadrantYLable"
        : "topQuadrantYLable";

      updateYLabelTextInUserProjects(data.newText, data.isBottomQuadrant);
    });
  }

  function addUpdateXAxesListener() {
    return getUpdateXAxesTextListener().subscribe((data) => {
      updateXLabelTextInUserProjects(data);
    });
  }

  function addResetZoomAllListener() {
    return getResetZoomAllListener().subscribe(() => {
      updateZoomLevel(quadrant, 1);
    });
  }

  function addResetZoomListener() {
    return getResetZoomListener().subscribe((qNumber) => {
      const instance = quadrants.find((el) => el.number === qNumber);
      updateZoomLevel(instance, 1);
    });
  }

  function addUpdateZoomListener() {
    return getUpdateZoomListener().subscribe((qNumber) => {
      const instance = quadrants.find((el) => el.number === qNumber);
      const { zoomLevel } = instance.verticalNeighbor;
      updateZoomLevel(instance, zoomLevel);
    });
  }

  function deleteAllWorkingLinesListener() {
    return getDeleteAllWorkingLinesListener().subscribe((qNumber) => {
      if (quadrant.number === qNumber) {
        const oldWorkingLineArray = [...quadrant.workingLines];
        for (let i = 0; i < oldWorkingLineArray.length; i++) {
          WorkingLine.deleteWorkingLine(oldWorkingLineArray[i]);
        }
      }
    });
  }

  const updateYLabelTextInUserProjects = (newText, isBottom) => {
    userProjects[projectId].quadrants[isBottom ? 3 : 0].y.yAxisLabel = newText;
    userProjects[projectId].quadrants[isBottom ? 2 : 1].y.yAxisLabel = newText;
    setUserProjects({ ...userProjects });
  };

  const updateXLabelTextInUserProjects = (data) => {
    const targetQuadrant =
      userProjects[projectId].quadrants[data.targetQuadrantID];

    if (data.labelID === "xAxisLabel") {
      targetQuadrant.x.xAxisLabel = data.newText;
    }

    if (data.labelID === "headerText") {
      targetQuadrant.legend.headerText = data.newText;
    }

    setUserProjects({ ...userProjects });
  };

  function preventEventDefault() {
    const elementById = document.getElementById(`svg_${id}`);
    elementById.addEventListener("click", (e) => e.preventDefault());
    elementById.addEventListener("dblclick", (e) => e.preventDefault());
  }

  function calculateStartPointPosition(posX, posY, height, width) {
    let startPointPosX = 0;
    let startPointPosY = 0;
    if (quadrant.isFirstQuadrant) {
      startPointPosX = posX;
      startPointPosY = height - posY;
    }
    if (quadrant.isSecondQuadrant) {
      startPointPosX = width - posX;
      startPointPosY = height - posY;
    }
    if (quadrant.isThirdQuadrant) {
      startPointPosX = width - posX;
      startPointPosY = posY;
    }
    if (quadrant.isFourthQuadrant) {
      startPointPosX = posX;
      startPointPosY = posY;
    }
    return { startPointPosX, startPointPosY };
  }

  function extractAttributes(q) {
    const sequenceNumber = WorkingLine.nextSequenceNumber(q);
    const workingLineHorizontal = q.horizontalNeighbor.workingLines.find(
      (line) => line.sequenceNumber === sequenceNumber
    );
    const workingLineVertical = q.verticalNeighbor.workingLines.find(
      (line) => line.sequenceNumber === sequenceNumber
    );

    const attributes = {};
    if (workingLineVertical) {
      attributes.valueX = workingLineVertical.valueXAxis;
      attributes.color = workingLineVertical.color;
      attributes.strokeIndex = workingLineVertical.strokeIndex;
      attributes.strokeWidth = workingLineVertical.strokeWidth;
      attributes.posX = workingLineVertical.posX;
      attributes.posY = workingLineHorizontal?.posY || 100;
      attributes.name = workingLineVertical.name;
      attributes.isVisible = workingLineVertical.isVisible;
      return attributes;
    }

    if (workingLineHorizontal) {
      attributes.valueY = workingLineHorizontal.valueYAxis;
      attributes.color = workingLineHorizontal.color;
      attributes.strokeIndex = workingLineHorizontal.strokeIndex;
      attributes.strokeWidth = workingLineHorizontal.strokeWidth;
      attributes.posX = workingLineVertical?.posX || 100;
      attributes.posY = workingLineHorizontal.posY;
      attributes.name = workingLineHorizontal.name;
      attributes.isVisible = workingLineHorizontal.isVisible;
    }
    return attributes;
  }

  function addStartPoint(posX, posY) {
    const { startPointPosX, startPointPosY } = calculateStartPointPosition(
      posX,
      posY,
      quadrant.height,
      quadrant.width
    );

    svg
      .append("circle")
      .attr("transform", `translate(${startPointPosX},${startPointPosY})`)
      .attr("id", `circleId_start_point_${index}`)
      .attr("fill", "#f52154")
      .attr("r", 7)
      .style("cursor", "pointer");

    const startPointCircle = svg
      .append("circle")
      .attr("cx", startPointPosX)
      .attr("cy", startPointPosY)
      .attr("fill", "steelblue")
      .attr("r", 7)
      .attr("fill", "none")
      .attr("stroke", "#383a36")
      .attr("stroke-width", 2);

    // first time draw only once
    startPointCircle // beginning
      .attr("opacity", 1) // set opacity to 100%
      .attr("id", "pulseCircle") // set opacity to 100%
      .attr("r", 10) // set radius to 8
      .transition() // apply a transition
      .duration(1500) // apply it over 1500 milliseconds
      .attr("opacity", 0) // set transparency to 0
      .attr("r", 22) // increase radius to 20
      .transition(); // apply a transition

    function repeat() {
      if (quadrant.isFirstQuadrant && showAnimation) {
        startPointCircle // beginning
          .attr("opacity", 1) // set opacity to 100%
          .attr("r", 10) // set radius to 8
          .transition() // apply a transition
          .duration(1500) // apply it over 1500 milliseconds
          .attr("opacity", 0) // set transparency to 0
          .attr("r", 22) // increase radius to 20
          .transition() // apply a transition
          .on("end", repeat);
      }
    }
    repeat();

    const startPointDomElement = document.getElementById(
      `circleId_start_point_${index}`
    );
    if (!startPointDomElement) return;

    startPointDomElement.onmouseup = () => {
      showAnimation = false;
      const attributes = extractAttributes(quadrant);

      addWorkingLine(
        attributes.posX ? attributes.posX : 100,
        attributes.posY ? attributes.posY : 100,
        undefined,
        undefined,
        false,
        attributes.name,
        attributes.color,
        attributes.strokeWidth,
        attributes.strokeIndex
      );

      // fire event so that working lines are draggable
      const event = new Event("mouseenter");
      svgRef.current.dispatchEvent(event);
    };
  }

  function deletePreviousState() {
    WorkingLine.counterQ1 = 0;
    WorkingLine.counterQ2 = 0;
    WorkingLine.counterQ3 = 0;
    WorkingLine.counterQ4 = 0;
    quadrant.workingLines = [];
  }

  function invertAxisValueToPixelValue(valueX, valueY) {
    const pixelValueX = quadrant.x(valueX);
    const pixelValueY = quadrant.y(valueY);
    return { pixelValueX, pixelValueY };
  }

  function reinitializeData(oldData) {
    oldData.forEach((workingLine) => {
      const { pixelValueX, pixelValueY } = invertAxisValueToPixelValue(
        workingLine.valueXAxis,
        workingLine.valueYAxis
      );
      addWorkingLine(
        pixelValueX,
        pixelValueY,
        undefined,
        undefined,
        true,
        workingLine.name,
        workingLine.color,
        workingLine.strokeWidth,
        workingLine.strokeIndex,
        workingLine.isCombined,
        workingLine.arrowDirectionIndex,
        workingLine.sequenceNumber,
        workingLine.decimalPlaces,
        workingLine.isVisible,
        userProjects[projectId].showLabels
      );
    });
  }

  function redrawWorkingLines() {
    deletePreviousState();
    const oldData = readWorkingLinesFromLocalStorage(quadrant);
    reinitializeData(oldData);
  }

  function addZoom() {
    function generateRange(min, max, step = 1) {
      const entryList = Array.from(
        { length: Math.floor((max - min) / step) + 1 },
        (_, i) => min + i * step
      );
      return entryList;
    }

    function updateChartElements(quadrant, newX, newY) {
      // update x ,y
      quadrant.workingLines.forEach((workingLine) => {
        workingLine.x = newX;
        workingLine.y = newY;
      });

      // update working lines
      quadrant.workingLines.forEach((workingLine) => {
        const newValX = newX(workingLine.valueXAxis);
        const newValY = newY(workingLine.valueYAxis);
        workingLine.updateWorkingLineByZoom(newValX, newValY);
      });

      // update range index of axis
      quadrant.updateAxisRange(newX, newY);

      // redraw quadrant lines (background lines)
      const lineData = rangeFunction(
        quadrant.xMin * 0.8,
        quadrant.xMax * 1.15,
        quadrant.xNumberOfDecimalPoints
      );
      quadrant.lines.forEach((line, lineIndex) => {
        d3.select(`#quadrantLines_${quadrant.index}_${lineIndex}`)
          .data([lineData])
          .attr("d", lineFunction(line.z, newX, newY, quadrant.formulaY));
      });
    }

    // A function that updates the chart when the user zoom and thus new boundaries are available
    function zoomUpdate(event) {
      // recover the new scale
      const newX = event.transform.rescaleX(quadrant.x);
      const newY = event.transform.rescaleY(quadrant.y);
      quadrant.zoomTransform = event.transform;

      setLineCalculationData(newX, newY);

      // vertical scale
      const newXVerticalNeighbor = event.transform.rescaleX(
        quadrant.verticalNeighbor.x
      );
      const newYVerticalNeighbor = event.transform.rescaleY(
        quadrant.verticalNeighbor.y
      );
      // horizontal scale
      const newXHorizontalNeighbor = event.transform.rescaleX(
        quadrant.horizontalNeighbor.x
      );
      const newYHorizontalNeighbor = event.transform.rescaleY(
        quadrant.horizontalNeighbor.y
      );
      // diagonal scale
      const newXDiagonalNeighbor = event.transform.rescaleX(
        quadrant.diagonalNeighbor.x
      );
      const newYDiagonalNeighbor = event.transform.rescaleY(
        quadrant.diagonalNeighbor.y
      );

      const xFormatString =
        quadrant.xNumberOfDecimalPoints > 0
          ? `,.${quadrant.xNumberOfDecimalPoints}f`
          : ",.0f";
      const yFormatString =
        quadrant.yNumberOfDecimalPoints > 0
          ? `,.${quadrant.yNumberOfDecimalPoints}f`
          : ",.0f";

      const xFormatStringVerticalNeighbor =
        quadrant.verticalNeighbor.xNumberOfDecimalPoints > 0
          ? `,.${quadrant.verticalNeighbor.xNumberOfDecimalPoints}f`
          : ",.0f";
      const yFormatStringVerticalNeighbor =
        quadrant.verticalNeighbor.yNumberOfDecimalPoints > 0
          ? `,.${quadrant.verticalNeighbor.yNumberOfDecimalPoints}f`
          : ",.0f";

      const xFormatStringHorizontalNeighbor =
        quadrant.horizontalNeighbor.xNumberOfDecimalPoints > 0
          ? `,.${quadrant.horizontalNeighbor.xNumberOfDecimalPoints}f`
          : ",.0f";
      const yFormatStringHorizontalNeighbor =
        quadrant.horizontalNeighbor.yNumberOfDecimalPoints > 0
          ? `,.${quadrant.horizontalNeighbor.yNumberOfDecimalPoints}f`
          : ",.0f";

      // store the current zoom scale
      if (quadrant.zoomLevel === event.transform.k) return;

      quadrant.zoomLevel = event.transform.k;

      if (event.transform.k === 1) {
        resetZoomTrigger(quadrant.number);
      }

      // update zoom level
      updateChartElements(quadrant, newX, newY);

      const entryList = generateRange(
        quadrant.xMin,
        quadrant.xMax,
        quadrant.xMainInterval
      );

      const updateAxisScalingForAllQuadrants = () => {
        // horizontal neighbor
        quadrant.horizontalNeighbor.axisX.call(
          d3
            .axisBottom(newXHorizontalNeighbor)
            .tickFormat(d3.format(xFormatStringHorizontalNeighbor))
        );

        quadrant.horizontalNeighbor.axisY.call(
          d3
            .axisRight(newYHorizontalNeighbor)
            .tickFormat(d3.format(yFormatStringHorizontalNeighbor))
        );
        // vertical neighbor
        quadrant.verticalNeighbor.axisX.call(
          d3
            .axisTop(newXVerticalNeighbor)
            .tickFormat(d3.format(xFormatStringVerticalNeighbor))
        );

        quadrant.verticalNeighbor.axisY.call(
          d3
            .axisLeft(newYVerticalNeighbor)
            .tickFormat(d3.format(yFormatStringVerticalNeighbor))
        );
        // diagonal neighbor
        quadrant.diagonalNeighbor.axisX.call(
          d3
            .axisTop(newXVerticalNeighbor)
            .tickFormat(d3.format(xFormatStringVerticalNeighbor))
        );

        quadrant.diagonalNeighbor.axisY.call(
          d3
            .axisLeft(newYVerticalNeighbor)
            .tickFormat(d3.format(yFormatStringVerticalNeighbor))
        );
      };

      // update axes with these new boundaries
      switch (true) {
        case quadrant.isFirstQuadrant:
          quadrant.axisX.call(
            d3
              .axisBottom(newX)
              .tickValues(entryList)
              .tickFormat(d3.format(xFormatString))
          );
          quadrant.axisY.call(
            d3.axisLeft(newY).tickFormat(d3.format(yFormatString))
          );

          // update related vertical neighbor quadrant [Q1-Q4]
          if (quadrant.isSyncZooming) {
            quadrant.verticalNeighbor.axisX.call(
              d3
                .axisTop(newXVerticalNeighbor)
                .tickFormat(d3.format(xFormatStringVerticalNeighbor))
            );

            quadrant.verticalNeighbor.axisY.call(
              d3
                .axisLeft(newYVerticalNeighbor)
                .tickFormat(d3.format(yFormatStringVerticalNeighbor))
            );
          }
          // updating axis labels of [Q1,Q2,Q3,Q4] accoring to zoom scale
          if (quadrant.isLineSnappingEnabled) {
            updateAxisScalingForAllQuadrants();
          }
          break;
        case quadrant.isSecondQuadrant:
          quadrant.axisX.call(
            d3
              .axisBottom(newX)
              .tickValues(entryList)
              .tickFormat(d3.format(xFormatString))
          );

          quadrant.axisY.call(
            d3.axisRight(newY).tickFormat(d3.format(yFormatString))
          );

          // update related vertical neighbor quadrant [Q2-Q3]
          if (quadrant.isSyncZooming) {
            quadrant.verticalNeighbor.axisX.call(
              d3
                .axisTop(newXVerticalNeighbor)
                .tickFormat(d3.format(xFormatStringVerticalNeighbor))
            );

            quadrant.verticalNeighbor.axisY.call(
              d3
                .axisRight(newYVerticalNeighbor)
                .tickFormat(d3.format(yFormatStringVerticalNeighbor))
            );
          }
          // updating axis labels of [Q1,Q2,Q3,Q4] accoring to zoom scale
          if (quadrant.isLineSnappingEnabled) {
            updateAxisScalingForAllQuadrants();
          }
          break;
        case quadrant.isThirdQuadrant:
          quadrant.axisX.call(
            d3
              .axisTop(newX)
              .tickValues(entryList)
              .tickFormat(d3.format(xFormatString))
          );
          quadrant.axisY.call(
            d3.axisRight(newY).tickFormat(d3.format(yFormatString))
          );

          // update related vertical neighbor quadrant [Q3-Q2]
          if (quadrant.isSyncZooming) {
            quadrant.verticalNeighbor.axisX.call(
              d3
                .axisBottom(newXVerticalNeighbor)
                .tickFormat(d3.format(xFormatStringVerticalNeighbor))
            );

            quadrant.verticalNeighbor.axisY.call(
              d3
                .axisRight(newYVerticalNeighbor)
                .tickFormat(d3.format(yFormatStringVerticalNeighbor))
            );
          }
          // updating axis labels of [Q1,Q2,Q3,Q4] accoring to zoom scale
          if (quadrant.isLineSnappingEnabled) {
            updateAxisScalingForAllQuadrants();
          }
          break;
        case quadrant.isFourthQuadrant:
          quadrant.axisX.call(
            d3
              .axisTop(newX)
              .tickValues(entryList)
              .tickFormat(d3.format(xFormatString))
          );
          quadrant.axisY.call(
            d3.axisLeft(newY).tickFormat(d3.format(yFormatString))
          );

          // update related vertical neighbor quadrant [Q4-Q1]
          if (quadrant.isSyncZooming) {
            quadrant.verticalNeighbor.axisX.call(
              d3
                .axisBottom(newXVerticalNeighbor)
                .tickFormat(d3.format(xFormatStringVerticalNeighbor))
            );

            quadrant.verticalNeighbor.axisY.call(
              d3
                .axisLeft(newYVerticalNeighbor)
                .tickFormat(d3.format(yFormatStringVerticalNeighbor))
            );
          }
          // updating axis labels of [Q1,Q2,Q3,Q4] accoring to zoom scale
          if (quadrant.isLineSnappingEnabled) {
            updateAxisScalingForAllQuadrants();
          }
          break;
        default:
          break;
      }

      quadrant.xGrid.call(
        d3
          .axisBottom(quadrant.x)
          .scale(newX)
          .ticks(10)
          .tickSize(quadrant.height)
          .tickFormat("")
      );

      quadrant.yGrid.call(
        d3
          .axisLeft(quadrant.y)
          .scale(newY)
          .ticks(10)
          .tickSize(-quadrant.width)
          .tickFormat("")
      );

      if (quadrant.isSyncZooming) {
        updateChartElements(
          quadrant.verticalNeighbor,
          newXVerticalNeighbor,
          newYVerticalNeighbor
        );
      }
      if (quadrant.isLineSnappingEnabled) {
        updateChartElements(
          quadrant.horizontalNeighbor,
          newXHorizontalNeighbor,
          newYHorizontalNeighbor
        );
        updateChartElements(
          quadrant.verticalNeighbor,
          newXVerticalNeighbor,
          newYVerticalNeighbor
        );
        updateChartElements(
          quadrant.diagonalNeighbor,
          newXDiagonalNeighbor,
          newYDiagonalNeighbor
        );
      }
    }

    // Add a clipPath: everything out of this area won't be drawn.
    svg
      .append("defs")
      .append("SVG:clipPath")
      .attr("id", "clip")
      .append("SVG:rect")
      .attr("width", quadrant.width)
      .attr("height", quadrant.height)
      .attr("x", 0)
      .attr("y", 0);

    // Create the plot variable: where both the circles and the brush take place
    const plot = svg.append("g").attr("clip-path", "url(#clip)");

    // Set the zoom and Pan features: how much you can zoom, on which part, and what to do when there is a zoom
    const zoom = d3
      .zoom()
      .scaleExtent([1, 20]) // This control how much you can unzoom (x0.5) and zoom (x20)
      .extent([
        [0, 0],
        [quadrant.width, quadrant.height],
      ])
      .translateExtent([
        [0, 0],
        [quadrant.width, quadrant.height],
      ])
      .on("zoom", zoomUpdate);

    // This rect can recover pointer events: necessary to understand when the user zoom
    // This add an invisible rect on top of the chart area.
    plot
      .append("rect")
      .attr("width", quadrant.containerWidth)
      .attr("height", quadrant.containerHeight)
      .attr("z-index", 1)
      .style("fill", "transparent")
      .style("pointer-events", "all")
      .call(zoom)
      .on("mousedown.zoom", null);

    quadrant.zoom = zoom;
    quadrant.plot = plot;
  }

  function drawWaterMark() {
    quadrant.plot
      .append("text")
      .attr("x", quadrant.width / 2)
      .attr("y", quadrant.height / 2 + 25)
      .attr("text-anchor", "middle")
      .attr("class", "myLabel")
      .attr("class", "selectDisable")
      .attr("stroke", "blue")
      .style("opacity", "0.4")
      .style("font-size", "60px")
      .text(quadrant.label);
  }

  useLayoutEffect(() => {
    removePreviousRender();

    quadrant = new Quadrant(
      id,
      formulaX,
      formulaY,
      formulaZ,
      isOnLeftSide,
      isOnBottomSide,
      index,
      viewBoxWidth,
      viewBoxHeight,
      currentProject,
      xMainInterval,
      yMainInterval,
      xNumberIntervals,
      yNumberIntervals,
      xNumberOfHelperIntervals,
      yNumberOfHelperIntervals,
      xNumberOfDecimalPoints,
      yNumberOfDecimalPoints,
      amountDataSeries,
      minDataSeries,
      stepsDataSeries,
      xMin,
      yMin,
      xMax,
      yMax,
      svgMargin,
      svgRef,
      areXAxisCongruent
    );

    addQuadrant(quadrant);
    quadrant.init();
    Quadrant.addQuadrants(quadrants);
    Quadrant.connectNeighbors();

    svg = quadrant.svg;

    addZoom();

    drawWaterMark();

    drawLines();

    addGrid();

    // init X-Labels
    addXLabel("top");
    addXLabel("bottom");

    // init Y-Labels
    addYLabel();

    addStartPoint(5, 5);

    redrawWorkingLines();

    const eventExtendHorizontal = extendWorkingLineHorizontalListener(quadrant);
    const eventExtendVertical = extendWorkingLineVerticalListener(quadrant);
    const eventResetAllZoom = addResetZoomAllListener();
    const eventResetZoom = addResetZoomListener();
    const eventUpdateZoom = addUpdateZoomListener();
    const eventWorkingLinesRemover = deleteAllWorkingLinesListener();
    const eventUpdateYAxis = addUpdateYAxesListener(quadrant);
    const eventUpdateXAxis = addUpdateXAxesListener(quadrant);

    preventEventDefault();

    // cleanup function
    return () => {
      eventExtendHorizontal.unsubscribe();
      eventExtendVertical.unsubscribe();
      eventResetAllZoom.unsubscribe();
      eventResetZoom.unsubscribe();
      eventUpdateZoom.unsubscribe();
      eventWorkingLinesRemover.unsubscribe();
      eventUpdateYAxis.unsubscribe();
      eventUpdateXAxis.unsubscribe();
      quadrant.unsubscribeSynchZooming();
    };
  }, [userProjects, location]);

  useEffect(() => {
    svgs[index] = svgRef.current;
    setSvgs([...svgs]);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: index === 2 || index === 1 ? "flex-end" : "flex-start",
      }}
    >
      <svg ref={svgRef} className={id} id={`svg_${id}`} />
    </div>
  );
}

export default Chart;
