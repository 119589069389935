import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp4(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant2yTyp4(x, z) {
  return x * z;
}

export function quadrant3yTyp4(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp4(x, z) {
  return safeDivByZero(x, z);
}
