import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Helvetica, Arial",
  },
  palette: {
    primary: {
      main: "#0064A7",
    },
    secondary: {
      main: "#78BACC",
    },
    text: {
      primary: "#0064A7",
      secondary: "#0064A7",
      disabled: "#0064A7",
      hint: "#0064A7",
    },
  },
  overrides: {
    MuiDrawer: {
      paperAnchorLeft: {
        marginTop: 48,
      },
    },
    palette: {
      primary: "#0064A7",
      secondary: "#78BACC",
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "0px solid red",
        },
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 15,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: "#0064A7",
        "& h2": {
          color: "white",
          textAlign: "center",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 15,
      },
    },
    MuiDropzoneArea: {
      icon: {
        width: 120,
        height: 100,
      },
    },
  },
});

export default theme;
