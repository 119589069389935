import React, { useEffect, useState } from "react";
import TreeItem from "@material-ui/lab/TreeItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  rootHeader: {
    color: "#0064A7",
    textAlign: "left",
    font: "normal normal bold 18px/21px Helvetica",
    letterSpacing: "0px",
    opacity: "1",
  },
  rootSubHeader: {
    textAlign: "left",
    font: "normal normal bold 16px/19px Helvetica",
    letterSpacing: "0px",
    color: "#78AACC",
    opacity: "1",
  },
  rootSubSubHeader: {
    textAlign: "left",
    font: "normal normal bold 12px/15px Helvetica",
    letterSpacing: "0px",
    color: "#78AACC",
    opacity: "1",
  },
});

const getTreeItemsFromData = (treeItems, isRoot) =>
  treeItems.map((treeItemData, index) => {
    let children;
    const classes = useStyles();
    const [isReady, setIsReady] = useState(false);

    if (treeItemData.children && treeItemData.children.length > 0) {
      children = getTreeItemsFromData(treeItemData.children, false);
    }
    const useHeaderStyle = isRoot ? classes.rootHeader : classes.rootSubHeader;

    const handleClick = (idx) => {
      if (!isRoot) {
        localStorage.setItem("projectId", idx);
        setIsReady(true);
      }
    };

    useEffect(() => {
      if (isReady) window.location.reload();
    }, [isReady]);

    return (
      <TreeItem
        className={useHeaderStyle}
        key={treeItemData.id}
        nodeId={treeItemData.id}
        label={treeItemData.name}
        onClick={() => handleClick(index)}
      >
        {children}
      </TreeItem>
    );
  });

export default getTreeItemsFromData;
