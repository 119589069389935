import React, { useCallback, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import workingLinesCalculation from "../helpers/WorkingLinesCalculation";
import workingLinesAlignment from "../helpers/WorkingLinesAlignment";
import LoginPage from "./LoginPage";
import WelcomePage from "./WelcomePage";
import ProjectPage from "./ProjectPage/ProjectPage";
import ChartPanel from "../components/graphs/ChartPanel";
import FileUploadPage from "./FileUploadPage";
import { useData } from "../components/DataProvider";
import ProtectedRoute from "../components/navigation/ProtectedRoute";
import { useQuery } from "../helpers/util";

// list of js functions which needs to be loaded on startup
function loadAdditionalJSFunctions(userProjects, setUserProjects, projectId) {
  workingLinesCalculation(userProjects, setUserProjects, projectId);
  workingLinesAlignment();
}

export default function App() {
  const { i18n } = useTranslation("home");
  const { userProjects, setUserProjects, projectId, planeItems, setLocation } =
    useData();
  numeral.locale(i18n.language);
  const query = useQuery();
  const languageQueryValue = query.get("lang");

  loadAdditionalJSFunctions(
    userProjects,
    setUserProjects,
    projectId,
    planeItems
  );

  const setLanguage = useCallback(() => {
    if (languageQueryValue !== null) {
      i18n.changeLanguage(languageQueryValue);
      numeral.locale("en-gb");
      setLocation(languageQueryValue);
      localStorage.setItem("langTriggered", "true");
      localStorage.setItem("i18nextLng", languageQueryValue);
    }
  }, [i18n, setLocation, languageQueryValue]);

  useEffect(() => {
    setLanguage();
  }, [setLanguage]);

  return (
    <BrowserRouter basename={process.env.REACT_APP_PATH}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />

        <ProtectedRoute exact path="/" component={WelcomePage} />
        <ProtectedRoute exact path="/welcome" component={WelcomePage} />
        <ProtectedRoute exact path="/project" component={ProjectPage} />
        <ProtectedRoute exact path="/chart" component={ChartPanel} />
        <ProtectedRoute exact path="/openProject" component={FileUploadPage} />
      </Switch>
    </BrowserRouter>
  );
}
