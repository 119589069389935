import { Container, Grid, Snackbar, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Chart from "./Chart";
import MainToolbar from "./toolbar/MainToolbar";
import { useData } from "../DataProvider";
import MiniToolbar from "./toolbar/MiniToolbar";
import Header from "../header/Header";
import Toast from "../../pages/Toast";
import DraggableTextField from "../ui-elements/DraggableTextField";
import { getTriggerTextField } from "../subjects/toolbar_subjects";
import { isObjectEmpty, nextUUID } from "../../helpers/util";
import getFormula from "../../helpers/FormulaHelpers";
import MiddleAxis from "../ui-elements/MiddleAxis";
import PdfDownloadDialog from "./pdfDownload/PdfDownloadDialog";
import ToastAlert from "../ui-elements/ToastAlert";
import DiagramConfigDialog from "./settings/DiagramConfigDialog";
import EditableTextField from "../ui-elements/EditableTextField";
import { showCombinedLineDisabledToastListener } from "../subjects/d3Subjects";

const useStyles = makeStyles((theme) => ({
  gridLayout: {
    marginLeft: 0,
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    marginTop: "10px",
    marginBottom: "5px",
    textAlign: "center",
  },
  bottomBarLeft: {
    textAlign: "right",
  },
  alignRight: {
    textAlign: "right",
    marginRight: 80,
  },
  container: {
    height: "20rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  eventDialogDelete: {
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "absolute",
      top: 3,
      right: "50%",
    },
    "& .MuiFab-sizeSmall": {
      position: "absolute",
      top: 9,
      right: "calc(50% + 6px)",
    },
  },
  spacer: {
    width: "100%",
    height: 10,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function ChartPanel() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userProjects, setUserProjects, projectId } = useData();
  const [showPdfDialog, setShowPdfDialog] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [showCombinedLineToast, setShowCombinedLineToast] = useState(false);
  const [activeQuadrant, setActiveQuadrant] = useState(null);
  const [titleEditMode, setTitleEditmode] = useState(false);
  const areXAxisCongruent = userProjects
    ? userProjects[projectId]?.areXAxisCongruent
    : true;

  const titleText = t(
    `diagramTypes.type${userProjects[projectId].typeNumber}.title`
  );
  let initTextFields = [];
  if (
    !isObjectEmpty(userProjects) &&
    Object.prototype.hasOwnProperty.call(userProjects[projectId], "textFields")
  ) {
    initTextFields = userProjects[projectId].textFields;
  }

  const [textFields, setTextFields] = React.useState(initTextFields);

  const handleLabelClick = (id, quadrant) => {
    setActiveQuadrant(quadrant.index + 1);
  };

  const handleTitleChange = (newTitle) => {
    const updatedProjects = {
      ...userProjects,
      [projectId]: { ...userProjects[projectId], title: newTitle },
    };
    setUserProjects({ ...updatedProjects });
  };

  const deleteValue = (id) => {
    if (
      Object.prototype.hasOwnProperty.call(
        userProjects[projectId],
        "textFields"
      )
    ) {
      const textFieldArray = userProjects[projectId].textFields;
      userProjects[projectId].textFields = textFieldArray.filter(
        (el) => el.id !== id
      );
    }
    setTextFields(textFields.filter((el) => el.id !== id));
    setUserProjects({ ...userProjects });
  };

  showCombinedLineDisabledToastListener().subscribe(() => {
    setShowCombinedLineToast(true);
  });

  getTriggerTextField().subscribe(() => {
    const newTextFieldObject = {
      id: nextUUID(),
      posX: window.innerWidth / 2 - 75,
      posY: window.innerHeight / 2 - 75,
      inputValue: "",
    };

    const newTextFields = [...textFields, newTextFieldObject];
    setTextFields(newTextFields);
    userProjects[projectId].textFields = newTextFields;
    setUserProjects({ ...userProjects });
  });

  return (
    <>
      <div id="body">
        <Header />
        <Toast />
        <ToastAlert
          open={isSuccessful}
          msg={t("successMsgOnSave")}
          setOpen={setIsSuccessful}
        />
        <Snackbar
          open={showCombinedLineToast}
          autoHideDuration={3000}
          onClose={setShowCombinedLineToast}
          message={t("Combined_Line_Disabled")}
        />
        <DiagramConfigDialog
          isVisible={activeQuadrant != null}
          close={() => setActiveQuadrant(null)}
          qNumber={activeQuadrant}
        />
        <div id="mainChartPanel">
          {userProjects[projectId]?.textFields?.map((textFieldObject) => (
            <DraggableTextField
              key={textFieldObject.id}
              id={textFieldObject.id}
              posX={textFieldObject.posX}
              posY={textFieldObject.posY}
              value={textFieldObject.inputValue}
              deleteValue={deleteValue}
              userProjects={userProjects}
              updateUserProjects={(allUserProjects) => {
                setUserProjects({ ...allUserProjects });
              }}
              width={400}
            />
          ))}
          {userProjects && userProjects[projectId] !== "undefined" && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <MainToolbar
                  isStoring={isStoring}
                  updateStoring={(value) => setIsStoring(value)}
                  openShowDialog={() => setShowPdfDialog(true)}
                  updateSuccessState={setIsSuccessful}
                />
              </div>

              {showPdfDialog && (
                <PdfDownloadDialog close={() => setShowPdfDialog(false)} />
              )}

              <MiniToolbar />

              <div id="quadrantPanel">
                {
                  // Textfields are not centered during SVG export, therefore it is only used to edit the text.
                  // For svg and PDF export, the regular text element is used
                  titleEditMode ? (
                    <div className={classes.headerContainer}>
                      <EditableTextField
                        id="project-title-text"
                        fontSize={20}
                        width={500}
                        isMultiline
                        value={userProjects[projectId]?.title || titleText}
                        isAbsolute={false}
                        isCentered
                        onMouseLeave={() => setTitleEditmode(false)}
                        onChange={(data) => {
                          handleTitleChange(data);
                        }}
                      />
                    </div>
                  ) : (
                    <div onClick={() => setTitleEditmode(true)}>
                      <Typography
                        id="project-title-text-pdf"
                        style={{
                          textAlign: "center",
                          marginTop: 5,
                        }}
                        variant="h6"
                      >
                        {userProjects[projectId]?.title || titleText}
                      </Typography>
                    </div>
                  )
                }
                <Grid container>
                  <Grid item xs={6} className={classes.gridLayout}>
                    <Chart
                      id="quadrant_2"
                      formulaX={getFormula(
                        userProjects[projectId]?.typeNumber,
                        2,
                        "x"
                      )}
                      formulaY={getFormula(
                        userProjects[projectId]?.typeNumber,
                        2,
                        "y"
                      )}
                      formulaZ={getFormula(
                        userProjects[projectId]?.typeNumber,
                        2,
                        "z"
                      )}
                      isOnLeftSide
                      index={1}
                      areXAxisCongruent
                      onLabelClick={handleLabelClick}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Chart
                      id="quadrant_1"
                      formulaX={getFormula(
                        userProjects[projectId]?.typeNumber,
                        1,
                        "x"
                      )}
                      formulaY={getFormula(
                        userProjects[projectId]?.typeNumber,
                        1,
                        "y"
                      )}
                      formulaZ={getFormula(
                        userProjects[projectId]?.typeNumber,
                        1,
                        "z"
                      )}
                      index={0}
                      onLabelClick={handleLabelClick}
                      areXAxisCongruent={
                        userProjects[projectId]?.areXAxisCongruent
                      }
                    />
                  </Grid>
                </Grid>
                <Container>
                  <MiddleAxis
                    onLabelClick={handleLabelClick}
                    userProjects={userProjects}
                    projectId={projectId}
                    areXAxisCongruent={areXAxisCongruent}
                  />
                </Container>
                <div className={classes.spacer} />
                <Grid container>
                  <Grid item xs={6} className={classes.gridLayout}>
                    <Chart
                      id="quadrant_3"
                      formulaX={getFormula(
                        userProjects[projectId]?.typeNumber,
                        3,
                        "x"
                      )}
                      formulaY={getFormula(
                        userProjects[projectId]?.typeNumber,
                        3,
                        "y"
                      )}
                      formulaZ={getFormula(
                        userProjects[projectId]?.typeNumber,
                        3,
                        "z"
                      )}
                      onLabelClick={handleLabelClick}
                      isOnLeftSide
                      isOnBottomSide
                      index={2}
                      areXAxisCongruent
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Chart
                      id="quadrant_4"
                      formulaX={getFormula(
                        userProjects[projectId]?.typeNumber,
                        4,
                        "x"
                      )}
                      formulaY={getFormula(
                        userProjects[projectId]?.typeNumber,
                        4,
                        "y"
                      )}
                      formulaZ={getFormula(
                        userProjects[projectId]?.typeNumber,
                        4,
                        "z"
                      )}
                      isOnBottomSide
                      onLabelClick={handleLabelClick}
                      index={3}
                      areXAxisCongruent={
                        userProjects[projectId]?.areXAxisCongruent
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
          {!userProjects && (
            <div>
              Loading diagram...
              <p>
                If diagram is not loading within some seconds please check if
                you already have created at least one diagram. If not please go
                back to project page and choose a diagram type.{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
