import LZString from "lz-string";

const UseSVGHelper = (userProject) => {
  const convert = async () =>
    Promise.all(
      userProject.dataUrls.map(async (compressedSvgString) => {
        const decompressSvgString = decompress(compressedSvgString);
        return decompressSvgString;
      })
    );

  const decompress = (dataStr) => LZString.decompress(dataStr);
  return convert();
};

export default UseSVGHelper;
