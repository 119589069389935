import { safeDivByZero } from "../../helpers/util";

export function quadrant1yTyp6(x, z) {
  return x * z;
}

export function quadrant2yTyp6(x, z) {
  //return x * z - (100 * x) / -100;
  return x * (1 - z / 100);
}
export function quadrant3yTyp6(x, z) {
  return safeDivByZero(x, z);
}

export function quadrant4yTyp6(x, z) {
  return x * z;
}
