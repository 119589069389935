import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp13(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant2xTyp13(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant3xTyp13(y, z) {
  return y * z;
}

export function quadrant4xTyp13(y, z) {
  return y * z;
}
