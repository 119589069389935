import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { ClickAwayListener, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { roundDecimalPlaces } from "../../../helpers/util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 220,
    marginLeft: "40%",
  },
  typography: {
    padding: theme.spacing(2),
  },
}));

export default function AreaOfTextFields({
  name,
  valueX,
  valueY,
  updatePosition,
  close,
  decimalPlaces,
  enabled
}) {
  const classes = useStyles();
  const [xValue, setXValue] = useState(
    roundDecimalPlaces(valueX, decimalPlaces)
  );
  const [yValue, setYValue] = useState(
    roundDecimalPlaces(valueY, decimalPlaces)
  );
  const { t } = useTranslation();

  function handleXChange(event) {
    setXValue(event.target.value);
  }

  function handleYChange(event) {
    setYValue(event.target.value);
  }

  function handleClickAway() {
    updatePosition(xValue, yValue);
    close();
  }

  const keyPressed = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      updatePosition(xValue, yValue);
      close();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.root}>
        <Paper>
          <Typography variant="subtitle2" className={classes.typography}>
            {name}
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <TextField
              id="outlined-number"
              label={t("X_Wert")}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={xValue}
              onChange={handleXChange}
              onKeyDown={keyPressed}
              style={{ margin: 5 }}
              disabled={!enabled}
            />
            <TextField
              id="outlined-number"
              label={t("Y_Wert")}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={yValue}
              onChange={handleYChange}
              onKeyDown={keyPressed}
              style={{ marginRight: 5 }}
              disabled={!enabled}
            />
          </div>
        </Paper>
      </div>
    </ClickAwayListener>
  );
}

AreaOfTextFields.propTypes = {
  name: PropTypes.string.isRequired,
  valueX: PropTypes.number.isRequired,
  valueY: PropTypes.number.isRequired,
  updatePosition: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  decimalPlaces: PropTypes.number.isRequired,
};
