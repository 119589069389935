export default class ArrowDirection {
  static diverge() {
    return {
      isLeft: true,
      isDown: true,
    };
  }

  static converge() {
    return {
      isLeft: false,
      isDown: false,
    };
  }

  static right() {
    return {
      isLeft: false,
      isDown: true,
    };
  }

  static left() {
    return {
      isLeft: true,
      isDown: false,
    };
  }
}
