import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp13(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant2zTyp13(x, y) {
  return safeDivByZero(y, x);
}

export function quadrant3zTyp13(x, y) {
  return safeDivByZero(x, y);
}

export function quadrant4zTyp13(x, y) {
  return safeDivByZero(x, y);
}
