import { safeDivByZero } from "../../helpers/util";

export function quadrant1zTyp9(x, y) {
  return safeDivByZero(y, x) * 1000;
}

export function quadrant2zTyp9(x, y) {
  return x * y;
}

export function quadrant3zTyp9(x, y) {
  return x - y;
}

export function quadrant4zTyp9(x, y) {
  return y - x;
}
