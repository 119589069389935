import { getWorkingLineAlignListener } from "../components/subjects/d3Subjects";

export default function WorkingLinesAlignment() {
  getWorkingLineAlignListener().subscribe((workingLineInstance) => {
    const { offsetX } = workingLineInstance;
    const { offsetY } = workingLineInstance;

    if (workingLineInstance.getHorizontalWorkingLine())
      workingLineInstance
        .getHorizontalWorkingLine()
        .updateWorkingLine(null, offsetY);

    if (workingLineInstance.getVerticalWorkingLine()) {
      const { areXAxisCongruent } =
        workingLineInstance.getVerticalWorkingLine().quadrant;
      if (areXAxisCongruent) {
        workingLineInstance
          .getVerticalWorkingLine()
          .updateWorkingLine(offsetX, null);
      }
    }
  });
}
