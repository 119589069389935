import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp3(y, z) {
  return safeDivByZero(y, z);
}

export function quadrant2xTyp3(y, z) {
  return y + z;
}

export function quadrant3xTyp3(y, z) {
  return z - y;
}

export function quadrant4xTyp3(y, z) {
  return safeDivByZero(1000 * y, z);
}
