import { safeDivByZero } from "../../helpers/util";

export function quadrant1xTyp12(y, z) {
  return safeDivByZero(100 * y, z);
}

export function quadrant2xTyp12(y, z) {
  return (y * z) / 100;
}

export function quadrant3xTyp12(y, z) {
  return y * z;
}

export function quadrant4xTyp12(y, z) {
  return y * z;
}
