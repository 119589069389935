import { Subject } from "rxjs";

const messageSubject$ = new Subject();

export function sendMessage(message) {
  messageSubject$.next(message);
}

export function receiveMessage() {
  return messageSubject$;
}
